<!--
#########################################################################################
######    Vista SVC11100  :  Consulta y Edición del MAPA DEL ALMACÉN               ######
#########################################################################################
-->
<template>
  <div>
      <!-- Top Navigation (Genérico) -->
      <TNC10000></TNC10000>

      <!-- Inicio del Cuerpo -->
      <div style="width:100%;text-align:center">

        <!--------------------------------------------->
        <!-- PRIMERA FILA : Herramientas Permanentes -->
        <!--------------------------------------------->
        <div class="row justify-content-center">
          <!-- Título y Barra de Herramientas -->
          <div class="col-md-6">
            <select class="igs-select-sm" v-model="lnSegundosTimer" @change="lp90CambioTimer()">
                <option value=0>No AutoRefrescar</option>
                <option value=10>Cada 10 segundos</option>
                <option value=20>Cada 20 segundos</option>
                <option value=30>Cada 30 segundos</option>
                <option value=60>Cada 60 segundos</option>
                <option value=90>Cada 90 segundos</option>
                <option value=120>Cada 120 segundos</option>
                <option value=150>Cada 150 segundos</option>
                <option value=180>Cada 180 segundos</option>
            </select>            
            &nbsp;
            <span class="igs-l3" @click="lp88CambiarTipoVista()">{{lf91TituloPantalla}}</span>
            <span class="igs-l3">
              &nbsp;Demarcación
              <select class="igs-select-sm" v-model="ltDemarcacion" @change="lp60ActualizarDemarcacion()">
                <option v-for="item in laListaDemarcaciones" :key="item.m101" :value="item.m101">{{ item.m101 }}</option>
              </select>
            </span>
            <span v-if="lbModoAlzada" class="igs-l3">
              &nbsp;&nbsp;Pasillo 
              <select class="igs-select-sm" v-model="ltMapaPasillo" @change="lp90RefrescarMapa()">
                <option v-for="item in laListaPasillos" :key="item.m102" :value="item.m102">{{ item.m102 }}</option>
              </select>
            </span>
            <span v-if="!lbModoAlzada" class="igs-l3">
              &nbsp;&nbsp;Altura
              <select class="igs-select-sm" v-model="ltMapaAltura" @change="lp90RefrescarMapa()">
                <option value="00">00</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="11">15</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select>
            </span>
            <span class="igs-l3">
              &nbsp;&nbsp;Escala
              <select class="igs-select-sm" v-model="lnMapaEscala" @change="lp90RefrescarMapa()">
                <option value="0.5">50%</option>
                <option value="0.66">66%</option>
                <option value="0.75">75%</option>
                <option value="0.9">90%</option>
                <option value="1">100%</option>
                <option value="1.25">125%</option>
                <option value="1.50">150%</option>
                <option value="1.75">175%</option>
                <option value="12">200%</option>
              </select>

            </span>
          </div>
          <div class="col-md-2 igs-l4">
            <span v-if="lnMapaEscala == 1"><label class="checkbox"><input type="checkbox" v-model="lSWMostrarHerramientasDibujo" @change="lp60SwitchHerramientasDibujo($event)">&nbsp;Dibujar</label>&nbsp;</span>
            <label class="checkbox"><input type="checkbox" v-model="lSWMostrarReticula">&nbsp;Retícula</label>&nbsp;
            <label class="checkbox"><input type="checkbox" v-model="lSWMostrarTooltip" @change="lp98LeerMapa()">&nbsp;Tooltip</label>&nbsp;
          </div>
          <div class="col-md-4">
            &nbsp;&nbsp;Herramienta
              <select class="igs-select-sm" v-model="lnTipoEdicion" @change="lp99ActualizarPanelDerecho()">
                <option value=0>Consultar Mapa</option>
                <option value=1>Editar Ubicaciones x Gama Artículo</option>
                <option value=2>Editar Caminos y Recorridos</option>
                <option value=11>Búsqueda de Artículos</option>
                <option value=12>Artículos bajo Mínimos</option>
                <option value=13>Muertos de Almacén</option>
                <option value=14>Reaprovisionamiento</option>
                <option value=91>Monitor Actividad (En Tránsito)</option>
              </select>   
              &nbsp;
              <span v-if="lnTipoEdicion == 0" class="fa fa-filter"          aria-hidden="true" @click="lp99MostrarLeyenda11()">&nbsp;&nbsp;</span> 
              <span                           class="fa fa-refresh"         aria-hidden="true" @click="lp90RefrescarDatosYMapa()">&nbsp;&nbsp;</span> 
              <span                           class="fa fa-question-circle" aria-hidden="true" @click="lp99MostrarLeyenda1()">&nbsp;&nbsp;</span> 
          </div>
        </div>

        <!------------------------------------->
        <!-- SEGUNDA FILA : En modo (Dibujo) -->
        <!------------------------------------->
        <div class="row justify-content-center igs-pad-0" v-if="lSWMostrarHerramientasDibujo">

          <!-- Nuevo Hueco -->
          <div class="col-md-1">
            <span @click="lp60CrearNuevoHueco()" class="igs-fs-10">&nbsp;<Fa :icon="faPlusSquare" /> Nuevo Hueco</span>
          </div>

          <!-- Heramientas de Dibujo -->
          <div class="col-md-8 igs-fs-10 igs-pad-0">
            <span>&nbsp;&nbsp;ID&nbsp;<input type="text" v-model="ltIdHuecoActivo" class="igs-fs-12 igs-left" placeholder="ID del Hueco"></span>
            <span>&nbsp;&nbsp;Artículo&nbsp;<input type="text" v-model="ltArticuloHuecoActivo" class="igs-fs-12 igs-left" placeholder="Artículo"></span>
            <span>&nbsp;&nbsp;Tipo Hueco&nbsp;
              <select v-model="ltTipoHuecoActivo" class="igs-fs-12 igs-left">
                <option value="0">0-Dinámico Estantería</option>
                <option value="1">1-Silo Dinámico (MonoProducto)</option>
                <option value="3">3-Estático (Producto Fijo)</option>
                <option value="4">4-Manual Estantería (No sugerible por SGA)</option>
                <option value="5">5-Cuarentena/Contaminado</option>
                <option value="6">6-Silo Dinámico (MultiProducto)</option>
              </select>
            </span>
            <span>&nbsp;&nbsp;N&deg; Palés&nbsp;<input type="text" v-model="ltNroPalesHuecoActivo" class="igs-fs-12 igs-left" placeholder="Número Palés"></span>
            <span class="igs-pad-2" @click="lp60ActualizarIdHueco()">&nbsp;&nbsp;<Fa :icon="faSave" /> Guardar Hueco</span>
          </div>

          <!-- Uso Futuro -->
          <div class="col-md-1 igs-fs-10">
             &nbsp;
          </div>

          <!-- Botón para Actualizar el Mapa en el Servidor -->
          <div class="col-md-1 igs-fs-10">
            <button class="btn-outline-primary ml-2" @click="lp98EnviarMapa()" v-if="lnTipoEdicion == 0 && !lbModoAlzada && lnMapaEscala == 1">Guardar Mapa</button>
          </div>

          <!-- Uso Futuro -->
          <div class="col-md-1 igs-fs-10">
             &nbsp;
          </div>

        </div>

        <!----------------------->
        <!-- Cuerpo Principal  -->
        <!----------------------->
        <div class="row justify-content-center"> <!-- Apertura del BODY -->
          
          <!-- Popup con Detalles del Hueco Activo -->
          <SVC11190 v-show="lSW11190_Visible" :visible="lSW11190_Visible" :ubicacion="ltIdHuecoActivo" :fechaInicial="ltFechaInicialDetalleMovimientos" @close="lp90Ocultar11190()"/>          

          <!-- Panel IZQUIERDO : Mapa -->
          <div :class="lf91ClasePanelIzquierdo">

            <div :class="lf91ClaseMapa" :style="lf91EstiloMapa" :key="lf91KeyMapa">
            
              <!-- Por cada HUECO -->
              <VueDragResize v-for="(rect, index) in laHuecosMapa"
                              :key="'vd_'+index"
                              :w="rect.width"
                              :h="rect.height"
                              :x="rect.left"
                              :y="rect.top"
                              :axis="rect.axis"
                              :isActive="rect.active"
                              :preventActiveBehavior="rect.preventActive"
                              :minw="rect.minw"
                              :minh="rect.minh"
                              :isDraggable="rect.draggable"
                              :isResizable="rect.resizable"
                              :parentLimitation="rect.parentLim"
                              :snapToGrid="rect.snapToGrid"
                              :aspectRatio="rect.aspectRatio"
                              :z="rect.zIndex"
                              :contentClass="rect.class"
                              v-on:clicked="lp60ConsultarHueco(index)"
                              v-on:activated="lp60ActivarHueco(index)"
                              v-on:deactivated="lp60DesactivarHueco(index)"
                              v-on:dragging="lp60CambioPosicionHueco($event, index)"
                              v-on:resizing="lp60CambioTamanoHueco($event, index)"
                >
                    <!-- Por cada Hueco -->
                    <div class="filler igs-fs-7" :style="{backgroundColor:rect.color}" :data-tooltip="lf91MapaTooltip(index)">
                      
                      <!-- Pasillo -->
                      <span v-if="rect.tipoHueco == '1' || rect.tipoHueco == '6'" :class="lf91ClaseEtiquetaPasillo(rect.width,rect.height)" :style="'color:#'+p80TextColorFromBackgroundColor(rect.color,'f0fff0','0f000f')+'; position:relative; top:6px;'">
                          <span v-if="$store.state.strFormatoRepresentacionMapa < '2' && (rect.width > rect.height) && (rect.width > 20) && (rect.height > 20)">Silo<br/></span>
                          <b>
                            <span v-if="(rect.width > 18)">{{rect.id.substr(2,2)}}</span>
                            <span v-if="($store.state.strFormatoRepresentacionMapa < '2') && (rect.width > 18)">/</span> 
                            <span v-if="($store.state.strFormatoRepresentacionMapa > '1') && (rect.width > 18)"><br/></span> 
                            {{rect.id.substr(4,2)}}
                          </b>
                      </span>
                      <!-- <br/> -->

                      <!-- Calle -->
                      <!-- <b    :style="'color:#'+p80TextColorFromBackgroundColor(rect.color,'fff0ff','000f00')">{{rect.id.substr(4,2)}}</b><br/> -->

                      <!-- Hueco -->
                      <div style="font-size:6px;" v-if="(rect.width <= rect.height)">&nbsp;</div>  <!-- Complementar Verticalmente -->
                      <span :style="'color:#'+p80TextColorFromBackgroundColor(rect.color,'ffffff','000000')+';'" v-if="lnMapaEscala >= 0.6 && rect.id.substr(6,2) != '00'"><b>{{lf91RotuloHueco(rect.id.substr(6,2))}}</b></span>

                      <!-- Altura -->
                      <br v-if="lnMapaEscala >= 1.25"/>
                      <span :style="'color:#'+p80TextColorFromBackgroundColor(rect.color,'77ffff','880000')" v-if="lnMapaEscala >= 1.25">{{rect.id.substr(8,2)}}</span>
                    </div>

                    <!-- Rótulos PLANTA -->
                    <div v-if="!lbModoAlzada && lf88NuevoPasillo(rect.id) && (lnTipoEdicion == 0)" class="igs-floating-v-label igs-color0 igs-back-color7 igs-l6 igs-center" :style="lf30PosicionLabelPlantaPasillo(rect.left, rect.top, index)">
                      <b>P.{{rect.id.substr(2,2)}}</b>
                    </div>

                    <div v-if="!lbModoAlzada && lf88NuevaCallePlanta(rect.id)" class="igs-floating-v-label igs-color0 igs-back-color7 igs-l4 igs-center" :style="lf30PosicionLabelPlantaCalle(rect.left, rect.top, index)">
                      <b>C. {{rect.id.substr(4,2)}}</b>
                    </div>

                    <!-- Rótulos ALZADA -->
                    <div v-if="lbModoAlzada && (index == 0)" class="igs-floating-v-label igs-color0 igs-back-color7 igs-l6 igs-center" :style="lf30PosicionLabelAlzadaPasillo(rect.left, rect.top)">
                      <b>Pasillo {{rect.id.substr(2,2)}}</b>
                    </div>

                    <div v-if="lbModoAlzada && (rect.id.substr(4,4) == '0101')" class="igs-floating-v-label igs-color0 igs-back-color7 igs-l3" :style="lf30PosicionLabelAlzadaAltura(rect.left, rect.top)">
                      <b>Altura<br>{{rect.id.substr(8,2)}}</b>
                    </div>

                    <div v-if="lbModoAlzada && lf88NuevaCalleAlzada(rect.id)" class="igs-floating-v-label igs-color0 igs-back-color7 igs-l4 igs-center" :style="lf30PosicionLabelAlzadaCalle(rect.left, rect.top, index)">
                      <b>C. {{rect.id.substr(4,2)}}</b>
                    </div>


                </VueDragResize> 

                <span v-if="lnTipoEdicion == 2 && !lbModoAlzada">
                  <span  v-for="(r, index) in laSentidoCamino" :key="'sc_'+index" :class="lf50ClaseCamino(r.s,r.x,r.y)" :style="'position:absolute; left:'+r.x+'px;top:'+r.y+'px;font-size:10px;color:'+r.color" aria-hidden="true">&nbsp;{{r.t}}</span>
                </span>
            </div>    
          </div>

          <!--==================================-->
          <!--    Panel DERECHO : Herramientas  -->
          <!--==================================-->

          <!-- 0-Nada -->
          <div :class="lf91ClasePanelDerecho" style="overflow-x:auto; overflow-y:scroll; height:82vh" v-if="lnTipoEdicion == 0">
          </div>

          <!-- 1-Gamas x Artículo -->
          <div :class="lf91ClasePanelDerecho" style="overflow-x:auto; overflow-y:scroll; height:82vh" v-if="lnTipoEdicion == 1">
            <div class="row">
              <div class="col-md-8 igs-color2"><h6><b>Ubicaciones x Agrupación de Artículo</b></h6></div>
              <div class="col-md-4 igs-center"><span @click="lp60CrearNuevoUXA()" class="igs-fs-12">&nbsp;<Fa :icon="faPlusSquare" />&nbsp;Nueva Ubicación</span></div>
            </div>
            <table class="table table-striped table-bordered igs-fs-12" style="width:98%">
              <thead>
                <tr>
                    <th>Activo</th>
                    <th>Descripción</th>
                    <th>Color</th>
                    <th>Mapa</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(uxa, index) in laRegistrosUXA.slice(0,-1)" :key="uxa.ucl0">
                    <td><span :style="lf91EstiloColor(lf91ColorEstado(uxa.u004))+'; border-radius: 50%;'">&nbsp;&nbsp;&nbsp;&nbsp;</span></td>
                    <td @click="lp60EditarUXA(index)" class="igs-left">{{uxa.u007}}</td>
                    <td><span :style="lf91EstiloColor(uxa.u041)">&nbsp;&nbsp;&nbsp;</span></td>
                    <td><input type="checkbox" :id="uxa.ucl0" :value="uxa.ucl0" v-model="laUXASeleccionados"></td>
                </tr>
            </tbody>
            </table>
          </div>

          <!-- 2-Caminos y Recorridos -->
          <div :class="lf91ClasePanelDerecho" style="overflow-x:auto; overflow-y:scroll; height:82vh" v-if="lnTipoEdicion == 2">
            <div class="row">
              <div class="col-md-2 igs-color1 igs-fs-12 igs-pad-4 igs-center" @click="lp60NuevoCYR()">&nbsp;&nbsp;<Fa :icon="faFile" /> Añadir</div>
              <div class="col-md-7 igs-color2"><h6><b>Caminos y Recorridos</b></h6></div>
              <div class="col-md-3 igs-color1 igs-fs-12 igs-pad-4 igs-center" @click="lp60ActualizarCYR()">&nbsp;&nbsp;<Fa :icon="faSave" /> Guardar</div>
            </div>
            <div class="row">
              <div class="col-md-3 igs-right igs-l3">Tipo Camino</div>
              <div class="col-md-8 igs-left">&nbsp;
                <select v-model="ltTipoCYR" @change="lp98LeerListaCYR()">
                      <option value="0">Predeterminado</option>
                      <option value="1">Alternativa .1.</option>
                      <option value="2">Alternativa .2.</option>
                      <option value="3">Alternativa .3.</option>
                      <option value="4">Alternativa .4.</option>
                      <option value="5">Alternativa .5.</option>
                      <option value="6">Alternativa .6.</option>
                      <option value="7">Alternativa .7.</option>
                      <option value="8">Alternativa .8.</option>
                      <option value="9">Alternativa .9.</option>
                    </select>
              </div>
              <div class="col-md-1">&nbsp;</div>
            </div>
            <div class="row igs-back-color8" style="width:99%;">
              <!-- <div class="col-md-1">&nbsp;</div> -->
              <div class="col-md-6 igs-l2 igs-left igs-mt-5" @click="lp60OrdenarCYR(2)">Orden - Descripción</div>
              <div class="col-md-2 igs-center igs-mt-5 igs-l2" @click="lp60OrdenarCYR(13)">&nbsp;Posición</div>
              <div class="col-md-2 igs-mt-5 igs-l2 igs-color2 igs-center" @click="lp60OrdenarCYR(14)">Zona</div>
              <div class="col-md-2 igs-mt-5 igs-l1 igs-fs-10 igs-color2 igs-center" @click="lp60OrdenarCYR(15)">Color</div>
            </div>
            <draggable v-model="laRegistrosCYR" group="CYR" @start="drag=true" @end="drag=false">
              <div v-for="(cyr, index) in laRegistrosCYR" :key="cyr.ycl0" class="row" style="border-style:dotted; border-width:1px; border-color:#0f0f0f; width:99%; text-align:left;padding:0 0 0 0;" >
                <!-- <div class="col-md-1" @click="lp60EditarCYR(index)"><Fa :icon="faEdit" style="width:28px"/></div> -->
                <div class="col-md-6 igs-l2" @click="lp60EditarCYR(index)">{{index+1}} - {{cyr.y003}}</div>
                <div class="col-md-2 igs-center igs-fs-11">{{ lf91DescripcionPosicionCYR(cyr.y013) }}</div>
                <div class="col-md-2 igs-l3 igs-color2 igs-center">{{cyr.y014}} ({{cyr.y016}})</div>
                <div class="col-md-2 igs-l3 igs-l1 igs-color2 igs-center"><span :style="lf91EstiloColor(cyr.y015)">&nbsp;&nbsp;&nbsp;</span></div>
              </div>
            </draggable>
          </div>

          <!-- 11-Búsqueda de Artículos -->
          <div :class="lf91ClasePanelDerecho" style="overflow-x:auto; overflow-y:scroll; height:82vh" v-if="lnTipoEdicion == 11">
            <div class="row">
              <div class="col-md-12 igs-color2"><h6><b>Búsqueda de Artículos</b><hr/></h6></div>
            </div>
            <div class="row">
              <div class="col-md-3">Artículo</div>
              <div class="col-md-3 igs-l2">
                &nbsp;
                <!-- <input type="text" v-model="ltArticuloBusquedaCodigo" size="8" v-on:keyup.enter="lp60BusquedaArticulosBAM()" @input="(val) => (ltArticuloBusquedaCodigo = ltArticuloBusquedaCodigo.toUpperCase())" > -->
              </div>
              <div class="col-md-6" @click="lp60BusquedaArticulosBAM()">&nbsp;&nbsp;<Fa :icon="faSearch" /> Buscar</div>   
            </div>
            <div class="row">
              <div class="col-md-12">
                <Multiselect class="igs-l2" style="width:98%" v-model="loArticuloBusqueda" :options="$store.state.strListaArticulos" :taggable="false" :multiple="false"
                              :custom-label="lf91DescripcionArticulo"  track-by="a001"  @open="lf60ListaAbierta"  @close="lf60ListaCerrada"
                              placeholder="Artículo" deselectLabel=" " selectLabel=" " selectedLabel=" " required="false"
                              @select="lp60NuevoArticuloBuscado()">
                              <span class="igs-l2" slot="noResult"></span>
                </Multiselect>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 igs-l2" v-if="laRegistrosBAM.length > 0">
                <span class="igs-color1"><br/><b>{{ltArticuloBusquedaDescripcion}}</b></span>
              </div>

              <div class="row" v-if="!lbCambioArticuloBusquedaEnCurso && laRegistrosBAM.length > 0">
                <div class="col-md-3"><img :src="this.lf91ImagenArticulo500(ltArticuloBusquedaCodigo)" style="max-height:80px;max-width:80px;height:auto;width:auto;"></div>
                <div class="col-md-9">
                  <span class="igs-color1"><br/><b>{{lf91SumaCantidadesBAMLOT[0]}} {{ ltArticuloBusquedaFormato }}</b></span>
                  <span class="igs-color2" v-if="lf91SumaCantidadesBAMLOT[2] != 0"><br/><b>{{lf91SumaCantidadesBAMLOT[2]}} {{ ltArticuloBusquedaFormato }}</b></span> <span v-if="lf91SumaCantidadesBAMLOT[2] != 0" class="igs-l1 igs-color2">(Tránsito)</span>
                  <span class="igs-color7" v-if="lf91SumaCantidadesBAMLOT[4] != 0"><br/><b>{{lf91SumaCantidadesBAMLOT[4]}} {{ ltArticuloBusquedaFormato }}</b></span> <span v-if="lf91SumaCantidadesBAMLOT[4] != 0" class="igs-l1 igs-color1">(Picking)</span>
                </div>
              </div>

              <div class="col-md-12 igs-color7 igs-l2" v-if="laRegistrosBAM.length == 0">&nbsp;<h6><b>{{ltArticuloBusquedaDescripcion}}</b><hr/></h6></div>
            </div>

            <br/>

            <div v-if="!lbCambioArticuloBusquedaEnCurso">
              <div v-for="(bam, index) in laRegistrosBAM" :key="bam.m001" style="width:100%" class="row">
                <div class="col-md-12" v-if="lf91CantidadBAM(index) != 0" style="border-style:groove; border-width:1px; border-color:#99aabb; width:98%; text-align:left;padding:8 8 8 8;">
                  <div class="row">
                    <div class="col-md-7 igs-l1 igs-l-pad-20" @click="lp60ResaltarBAM(index)">
                      <span  v-html="f93MaquetarUbicacion(bam.m001,5)"></span>
                    </div>
                    <div class="col-md-2 igs-l3 igs-pad-0">
                      <span v-html="lf91CantidadBAM(index)"/>
                    </div>
                    <div class="col-md-3 igs-right igs-r-pad-20">
                      <span v-if="bam.mdma.length > 0">&nbsp;<Fa :icon="faClock" />&nbsp;</span>
                      <span v-if="bam.m001 == ltHuecoResaltado" @click="lp60ConsultarUbicacionBAM(index)"><Fa :icon="faCheckSquare" />&nbsp;</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="laRegistrosBAM.length > 0">
              <div class="row">
                <div class="col-md-12 igs-left igs-mt-10 igs-vl3 igs-color7">
                  <input type="checkbox" v-model="lbMostrarActividadPasadaCerrada" @change="lp30FechaInicialDetalleMovimientos()">&nbsp;Mostrar Actividad Cerrada desde el&nbsp; 
                  <date-range-picker
                              ref="dp_FechaInicialDetalleMovimientos"
                              opens="left"
                              :append-to-body=true
                              :single-date-picker=true
                              :locale-data="dp1_localeData"
                              minDate="2022-01-01" maxDate="2035-12-31"
                              :timePicker=true
                              :timePicker24Hour=true
                              :showWeekNumbers=true
                              :showDropdowns=true
                              :autoApply=true
                              :ranges="dp1_defaultRanges"
                              :linkedCalendars=false
                              control-container-class="igs-btn-dh-fecha"
                              v-model="dp1_dateRange"
                              @update="lp30FechaInicialDetalleMovimientos"
                              >
                          <template v-slot:input="picker">
                            <div class="igs-color7 igs-mt-10 igs-vl3">
                              <b>{{ picker.startDate | lf60FiltroFecha }}</b>
                            </div>
                          </template>
                        </date-range-picker>

                </div>
              </div>
            </div>
          </div>

          <!-- 12-Artículos Bajo Mínimos -->
          <div :class="lf91ClasePanelDerecho" style="overflow-x:auto; overflow-y:scroll; height:82vh" v-if="lnTipoEdicion == 12">
            <div class="row">
              <div class="col-md-12 igs-color2"><h6><b>Artículos Bajo Mínimos</b></h6></div>
            </div>
          </div>

          <!-- 13-Muertos de Almacén -->
          <div :class="lf91ClasePanelDerecho" style="overflow-x:auto; overflow-y:scroll; height:82vh" v-if="lnTipoEdicion == 13">
            <div class="row">
              <div class="col-md-12 igs-color2"><h6><b>Muertos de Almacén</b></h6></div>
            </div>
          </div>

          <!-- 14-Reaprovisionamiento -->
          <div :class="lf91ClasePanelDerecho" style="overflow-x:auto; overflow-y:scroll; height:82vh" v-if="lnTipoEdicion == 14">
            <div class="row">
              <div class="col-md-12 igs-color2"><h6><b>Reaprovisionamiento</b></h6></div>
            </div>
          </div>


          <!-- 91-Monitor Movimientos Pendientes de Cierre (en Tránsito)  -->
          <div :class="lf91ClasePanelDerecho" style="overflow-x:auto; overflow-y:scroll; height:82vh" v-if="lnTipoEdicion == 91">

                <div class="row">
                  <div class="col-md-12 igs-color2"><h6><b>Monitor Movimientos en Tránsito</b></h6></div>
                </div>

                <div class="row">
                  <div class="col-md-6 igs-color2 igs-l2 igs-left igs-pad-2">
                    Mostrar ...... &nbsp;
                    <select v-model="lnFiltroTMRegistrosMET" @change="lp60FiltrarRegistrosMET()">
                      <option value=0>Todos</option>
                      <option value=1>Compras</option>
                      <option value=2>Ventas</option>
                      <option value=3>Traslados Almacén</option>
                      <option value=4>Recuentos</option>
                      <option value=9>Otros</option>
                    </select>
                  </div>
                  <div class="col-md-6 igs-color2 igs-l2 igs-left igs-pad-2">
                    Ordenar por ...&nbsp;
                    <select v-model="lnOrdenRegistrosMET" @change="lp50OrdenarRegistrosMET()">
                      <option value=0>Ubicación</option>
                      <option value=1>Artículo</option>
                      <option value=2>Fecha/Hora</option>
                    </select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-8 igs-color2 igs-l2 igs-left igs-pad-2">
                    Almacenero &nbsp;
                    <select v-model="ltFiltroOPERegistrosMET" @change="lp60FiltrarRegistrosMET()">
                      <option v-for="operario in laListaOperariosMET" :value="operario" :key="operario">{{operario}}</option>
                    </select>
                  </div>
                  <div class="col-md-4 igs-color2 igs-l2 igs-center igs-pad-2">
                    <label class="checkbox"><input type="checkbox" style="width:10px;height:10px" v-model="lbMarcarDesmarcarMET" @click="lp60MarcarDesmarcarMET()">&nbsp;Todo/Nada</label>&nbsp;&nbsp;
                    <span class="igs-color1 igs-fs-12 igs-pad-4" @click="lp60BorrarRegistrosMETSeleccionados()">&nbsp;<Fa :icon="faTrash" /></span>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-8 igs-color2 igs-l2 igs-left igs-pad-2">
                    Valor a Filtrar &nbsp;
                    <input type="text" v-model="ltFiltroTextoMET" maxlength="30" size="20" style="height:1.4em" @change="lp60FiltrarRegistrosMET()" />
                  </div>
                </div>

                <div v-for="(met, index) in laRegistrosMET" :key="index" class="row" style="border-style:groove; border-width:1px; border-color:#99aabb; width:99%; text-align:left;padding:0 0 0 0;">
                  <!-- Fotografía --->
                  <div class="col-md-3 igs-center igs-pad-0" @click="lp60ResaltarMET(index)">
                    <img :src="lf91ImagenArticulo500(met.m022)" style="max-height:60px;max-width:60px;height:auto;width:auto;">
                  </div>

                  <!-- Textos -->
                  <div class="col-md-9">

                    <!-- Ubicación -->
                    <div class="row">
                      <div class="col-md-9 igs-l1 igs-pad-0" @click="lp60ResaltarMET(index)">
                        <span  v-html="f93MaquetarUbicacion(met.m001,5)"></span>
                      </div>
                      <div class="col-md-3 igs-right igs-r-pad-4">
                        <span v-if="met.m001 == ltHuecoResaltado"><Fa :icon="faCheckSquare" />&nbsp;</span>
                        <!-- <span>&nbsp;<Fa :icon="faClock" :style="lf91ColorMET(index)"/>&nbsp;</span> -->
                        <span><img :src="lf91ImagenMET(index)" style="max-height:28px;max-width:28px;height:auto;width:auto;"></span>
                      </div>
                    </div>

                    <!-- Datos Artículo -->
                    <div class="row">
                      <div class="col-md-10 igs-l1 igs-pad-0">
                        <span class="igs-bold igs-color1">{{met.m022}} - {{ met.a002 }}</span>
                      </div>
                      <div class="col-md-2 igs-right">
                       <input type="checkbox" style="width:10px;height:10px" v-model="met.mdma[0].dsw1">
                      </div>
                    </div>

                    <!-- Otros Datos -->
                    <div class="row">
                      <div class="col-md-9 igs-l1 igs-pad-0">
                        <span class="igs-bold igs-color7">{{ f70AMD2sDMA(met.mdma[0].d083) }} {{ f70HMS2fHMS(met.mdma[0].d084) }} {{ met.mdma[0].d082 }}</span>
                        <span class="igs-l1 igs-color8" v-if="met.mdma[0].d009 == '1'"><br/>{{f93DescripcionTipoMovimientoDMA(met.mdma[0].d011,met.mdma[0].d009,true)}} ({{ f93DescripcionReferenciaTextoDMA(met.mdma[0].d011, met.mdma[0].d014, met.mdma[0].d013, met.mdma[0].d077) }})</span>
                        <span class="igs-l1 igs-color9" v-if="met.mdma[0].d009 == '4'"><br/>{{f93DescripcionTipoMovimientoDMA(met.mdma[0].d011,met.mdma[0].d009,true)}} ({{ f93DescripcionReferenciaTextoDMA(met.mdma[0].d011, met.mdma[0].d014, met.mdma[0].d013, met.mdma[0].d077) }})</span>
                        <span class="igs-l1 igs-color9" v-if="met.mdma[0].d009 == '5'"><br/>{{f93DescripcionTipoMovimientoDMA(met.mdma[0].d011,met.mdma[0].d009,true)}} ({{ f93DescripcionReferenciaTextoDMA(met.mdma[0].d011, met.mdma[0].d014, met.mdma[0].d013, met.mdma[0].d077) }})</span>
                      </div>
                      <div class="col-md-3 igs-l1 igs-right igs-pad-0 igs-color1 igs-bold">
                        <span class="igs-l3 igs-bold">{{f91Cantidad(met.mdma[0].d060)}}</span> {{met.a917}}&nbsp;
                      </div>
                    </div>

                  </div>

                </div>

          </div>


        </div> <!-- Cierre del BODY -->


        <!-- Pie del Mapa -->
        <div><br/></div>

      </div>
      
      <!-- Bottom Navigation (Genérico) -->
      <BNC10000></BNC10000>

      <!-- Ventana Modal .1. (Ayuda) -->
      <VModal name="VMLeyenda1" height="auto" @before-close="lp99OcultandoLeyenda1()" :draggable="true" :adaptive="true" :resizable="true">
        <div class="igs-back-color8" style="height:100%;">
          <div class="row">
            <div class="col-md-11 igs-back-color1"><h5 class="igs-color0 igs-center">Leyenda de Colores</h5></div>
            <div class="col-md-1 igs-color0 igs-back-color1 igs-l3" @click="$modal.hide('VMLeyenda1')"><Fa :icon="faWindowClose" /></div>
          </div>

          <div class="row igs-pad-2">
            <div class="col-md-3 igs-back-color7 igs-color0 igs-l3"><b>Huecos Con Stock ...</b></div>
            <div class="col-md-3 igs-back-color7 igs-color0 igs-l3"><b>Búsquedas</b></div>
            <div class="col-md-6 igs-back-color7 igs-color0 igs-l3"><b>Otros Estados</b></div>
          </div>

          <div class="row igs-pad-2">
            <div class="col-md-3 igs-left igs-l3">&nbsp;<span :style="lf91MPAColors(0)">&nbsp;&nbsp;&nbsp;</span> Negativo</div>
            <div class="col-md-3 igs-left igs-l3">&nbsp;<span :style="lf91MPAColors(7)">&nbsp;&nbsp;&nbsp;</span> No</div>
            <div class="col-md-6 igs-left igs-l3">&nbsp;<span :style="lf91MPAColors(10)">&nbsp;&nbsp;&nbsp;</span> Último Activo</div>
          </div>

          <div class="row igs-pad-2">
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-6 igs-left igs-l3">&nbsp;<span :style="lf91MPAColors(11)">&nbsp;&nbsp;&nbsp;</span> Agrup. Desconocida</div>
          </div>

          <div class="row igs-pad-2">
            <div class="col-md-3 igs-left igs-l3">&nbsp;<span :style="lf91MPAColors(1)">&nbsp;&nbsp;&nbsp;</span> Cero</div>
            <div class="col-md-3 igs-left igs-l3">&nbsp;<span :style="lf91MPAColors(8)">&nbsp;&nbsp;&nbsp;</span> Ni Sí ni No</div>
            <div class="col-md-6 igs-left igs-l3">&nbsp;</div>
          </div>

          <div class="row igs-pad-2">
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-6 igs-left igs-l3">&nbsp;<span :style="lf91MPAColors(12)">&nbsp;&nbsp;&nbsp;</span> Movimientos COMPRA NO Confirmados</div>
          </div>

          <div class="row igs-pad-2">
            <div class="col-md-3 igs-left igs-l3">&nbsp;<span :style="lf91MPAColors(2)">&nbsp;&nbsp;&nbsp;</span> 0,01% al 33,33%</div>
            <div class="col-md-3 igs-left igs-l3">&nbsp;<span :style="lf91MPAColors(9)">&nbsp;&nbsp;&nbsp;</span> Sí</div>
            <div class="col-md-6 igs-left igs-l3">&nbsp;<span :style="lf91MPAColors(13)">&nbsp;&nbsp;&nbsp;</span> Movimientos COMPRA YA Confirmados</div>
          </div>

          <div class="row igs-pad-2">
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-6 igs-left igs-l3">&nbsp;</div>
          </div>

          <div class="row igs-pad-2">
            <div class="col-md-3 igs-left igs-l3">&nbsp;<span :style="lf91MPAColors(3)">&nbsp;&nbsp;&nbsp;</span> 33,34% al 66,66%</div>
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-6 igs-left igs-l3">&nbsp;<span :style="lf91MPAColors(14)">&nbsp;&nbsp;&nbsp;</span> Movimientos CARGA NO Confirmados</div>
          </div>

          <div class="row igs-pad-2">
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-6 igs-left igs-l3">&nbsp;<span :style="lf91MPAColors(15)">&nbsp;&nbsp;&nbsp;</span> Movimientos CARGA YA Confirmados</div>
          </div>

          <div class="row igs-pad-2">
            <div class="col-md-3 igs-left igs-l3">&nbsp;<span :style="lf91MPAColors(4)">&nbsp;&nbsp;&nbsp;</span> 66,67% al 99,99%</div>
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-6 igs-left igs-l3">&nbsp;</div>
          </div>

          <div class="row igs-pad-2">
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-6 igs-left igs-l3">&nbsp;<span :style="lf91MPAColors(16)">&nbsp;&nbsp;&nbsp;</span> Movimientos RETORNO NO Confirmados</div>
          </div>

          <div class="row igs-pad-2">
            <div class="col-md-3 igs-left igs-l3">&nbsp;<span :style="lf91MPAColors(5)">&nbsp;&nbsp;&nbsp;</span> 100%</div>
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-6 igs-left igs-l3">&nbsp;<span :style="lf91MPAColors(17)">&nbsp;&nbsp;&nbsp;</span> Movimientos RETORNO YA Confirmados</div>
          </div>

          <div class="row igs-pad-2">
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-6 igs-left igs-l3">&nbsp;</div>
          </div>

          <div class="row igs-pad-2">
            <div class="col-md-3 igs-left igs-l3">&nbsp;<span :style="lf91MPAColors(6)">&nbsp;&nbsp;&nbsp;</span> > 100%</div>
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-6 igs-left igs-l3">&nbsp;<span :style="lf91MPAColors(18)">&nbsp;&nbsp;&nbsp;</span> Mvtos. ENTRE ALMACENES NO Confirmados</div>
          </div>

          <div class="row igs-pad-2">
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-6 igs-left igs-l3">&nbsp;<span :style="lf91MPAColors(19)">&nbsp;&nbsp;&nbsp;</span> Mvtos. ENTRE ALMACENES YA Confirmados</div>
          </div>

          <div class="row igs-pad-2">
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-6 igs-left igs-l3">&nbsp;</div>
          </div>

          <div class="row igs-pad-2">
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-6 igs-left igs-l3">&nbsp;<span :style="lf91MPAColors(20)">&nbsp;&nbsp;&nbsp;</span> Mvtos. de RECUENTO NO Confirmados</div>
          </div>

          <div class="row igs-pad-2">
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-6 igs-left igs-l3">&nbsp;<span :style="lf91MPAColors(21)">&nbsp;&nbsp;&nbsp;</span> Mvtos. de RECUENTO YA Confirmados</div>
          </div>


          <div class="row igs-pad-2">
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-6 igs-left igs-l3">&nbsp;</div>
          </div>

          <div class="row igs-pad-2">
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-6 igs-left igs-l3">&nbsp;<span :style="lf91MPAColors(22)">&nbsp;&nbsp;&nbsp;</span> Mvtos. VARIOS NO Confirmados</div>
          </div>

          <div class="row igs-pad-2">
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-6 igs-left igs-l3">&nbsp;<span :style="lf91MPAColors(23)">&nbsp;&nbsp;&nbsp;</span> Mvtos. VARIOS YA Confirmados</div>
          </div>

          <div class="row igs-pad-2">
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-3 igs-left igs-l3">&nbsp;</div>
            <div class="col-md-6 igs-left igs-l3">&nbsp;</div>
          </div>

        </div>
      </VModal>

      <!-- Ventana Modal .2. (Edición UXA) -->
      <VModal name="VMLeyenda2" height="auto" :minWidth=1024 width="90%" @before-close="lp99OcultandoLeyenda2()" :draggable="true" :adaptive="true" :resizable="true">
        <div class="igs-back-color8" style="height:100%;">
          <div class="row">
            <div class="col-md-6 igs-back-color2"><h6 class="igs-color0 igs-center igs-pad-2">Ubicación x Agrupación de Artículo</h6></div>
            <div class="col-md-2 igs-color0 igs-back-color2 igs-fs-12 igs-pad-4" @click="lp60BorrarRUXA()">&nbsp;&nbsp;<Fa :icon="faTrash" /> Borrar</div>              
            <div class="col-md-2 igs-color0 igs-back-color2 igs-fs-12 igs-pad-4" @click="lp60ActualizarRUXA()">&nbsp;&nbsp;<Fa :icon="faSave" /> Guardar</div>              
            <div class="col-md-2 igs-color0 igs-back-color2 igs-l3" @click="$modal.hide('VMLeyenda2')"><Fa :icon="faWindowClose" /></div>
          </div>

          <div class="row">
            <div class="col-md-8"></div>
            <div class="col-md-4 igs-fs-11">
              
            </div>
          </div>

          <!-- Descripciones -->
          <div class="igs-l4 igs-color7">
            <div class="row igs-fs-11 igs-left igs-pad-4">
              <div class="col-md-3">Descripción Corta</div>
              <div class="col-md-6"><input type="text" v-model="rUXA.u005" size="16" /></div>
              <div class="col-md-3">
                Activo
                <select name="rUXA.004">
                  <option value="S">Sí</option>
                  <option value="N">No</option>
                </select>
              </div>
            </div>

            <div class="row igs-fs-11 igs-left igs-pad-4">
              <div class="col-md-3 igs-v-center">Descripción Mediana</div>
              <div class="col-md-6 igs-v-center"><input type="text" v-model="rUXA.u006" size="32" /></div>
              <div class="col-md-1 igs-v-center">Color</div>
              <div class="col-md-2 igs-v-center">
                  <!--  <input type="text" v-model="rUXA.u041" size="8" />&nbsp;&nbsp; -->
                  <!-- <span :style="lf91EstiloColor('#'+rUXA.u041)">&nbsp;&nbsp;&nbsp;&nbsp;</span> -->
                  <v-swatches style="transform: scale(0.5); height:20px; position:relative; top:-10px;" swatches="text-advanced" shapes="circles" popover-x="left" swatch-size="32" v-model="rUXA.u041"></v-swatches>
              </div>
            </div>

            <div class="row igs-fs-11 igs-left igs-pad-4">
              <div class="col-md-3">Descripción Completa</div>
              <div class="col-md-9"><input type="text" v-model="rUXA.u007" size="76" /></div>
            </div>

            <div><hr/></div>

          <!-- Rangos de Ubicaciones y Otros Parámetros -->
          <div class="row igs-fs-11 igs-left igs-pad-4">
              <div class="col-md-6">
                <h6 class="igs-center">Rango de Ubicaciones</h6>
                <table class="igs-fs-12" style="width:98%" border="0">
                  <tr>
                    <td>&nbsp;</td>
                    <td align="center">Desde<br/>Ubicación</td>
                    <td align="center">Hasta<br/>Ubicación</td>
                  </tr>
                  <tr>
                    <td>Demarcación</td>
                    <td align="center"><input type="text" v-model="rUXA.u101" size="3" /></td>
                    <td align="center"><input type="text" v-model="rUXA.u201" size="3" /></td>
                  </tr>
                  <tr>
                    <td>Pasillo</td>
                    <td align="center"><input type="text" v-model="rUXA.u102" size="3" /></td>
                    <td align="center"><input type="text" v-model="rUXA.u202" size="3" /></td>
                  </tr>
                  <tr>
                    <td>Calle</td>
                    <td align="center"><input type="text" v-model="rUXA.u103" size="3" /></td>
                    <td align="center"><input type="text" v-model="rUXA.u203" size="3" /></td>
                  </tr>
                  <tr>
                    <td>Hueco</td>
                    <td align="center"><input type="text" v-model="rUXA.u104" size="3" /></td>
                    <td align="center"><input type="text" v-model="rUXA.u204" size="3" /></td>
                  </tr>
                  <tr>
                    <td>Altura / Estantería</td>
                    <td align="center"><input type="text" v-model="rUXA.u105" size="3" /></td>
                    <td align="center"><input type="text" v-model="rUXA.u205" size="3" /></td>
                  </tr>
                  <tr>
                    <td>Fila</td>
                    <td align="center"><input type="text" v-model="rUXA.u106" size="3" /></td>
                    <td align="center"><input type="text" v-model="rUXA.u206" size="3" /></td>
                  </tr>
                </table>

                <br/>

                <table class="igs-fs-12" style="width:98%" border="0">
                  <tr>
                    <td>&nbsp;</td>
                    <td align="center">Tramo <b>A</b></td>
                    <td align="center">Tramo <b>B</b></td>
                    <td align="center">Tramo <b>C</b></td>
                  </tr>
                  <tr>
                    <td>Calle Inicial</td>
                    <td align="center"><input type="text" v-model="rUXA.u021" size="3" /></td>
                    <td align="center"><input type="text" v-model="rUXA.u022" size="3" /></td>
                    <td align="center"><input type="text" v-model="rUXA.u023" size="3" /></td>
                  </tr>
                </table>
              </div>
              <div class="col-md-6">
                <h6 class="igs-center">Control Pulmón y Picking</h6>
                <br/><br/>
                <table class="igs-fs-12" style="width:98%" border="0">
                  <tr>
                    <td>Altura Inicial Nivel Pulmón<br/>(Altura / Estantería)</td>
                    <td align="center"><input type="text" v-model="rUXA.u042" size="3" /></td>
                  </tr>
                  <tr>
                    <td>Altura Final Nivel Pulmón<br/>(Altura / Estantería)</td>
                    <td align="center"><input type="text" v-model="rUXA.u046" size="3" /></td>
                  </tr>
                  <tr>
                    <td colspan="2"><hr/></td>
                  </tr>
                  <tr>
                    <td>Días Estadística</td>
                    <td align="center"><input type="text" v-model="rUXA.u043" size="3" /></td>
                  </tr>
                  <tr>
                    <td>Días Picking</td>
                    <td align="center"><input type="text" v-model="rUXA.u044" size="3" /></td>
                  </tr>
                  <tr>
                    <td>% Espacio para Picking</td>
                    <td align="center"><input type="text" v-model="rUXA.u045" size="3" /></td>
                  </tr>
                </table>

              </div>
            </div>

            <hr/>

            <!-- Agrupaciones de Artículo a Incluir / Excluir -->
            <div class="row igs-left igs-pad-4">
              <div class="col-md-6 igs-center igs-color0 igs-back-green">Agrupaciones Incluidas</div>
              <div class="col-md-6 igs-center igs-color0 igs-back-red">Agrupaciones Excluidas</div>
            </div>

            <div class="row igs-fs-11 igs-left igs-pad-4">
                <div class="col-md-3">
                  <table class="igs-fs-10" style="width:98%" border="0">
                    <tr>
                      <td align="center">Nro.</td>
                      <td align="center">Tipo Agrupación</td>
                      <td align="center">Código</td>
                    </tr>
                    <tr v-for="i in 10" :key="i">
                      <td align="center">{{i}}</td>
                      <td align="center">
                        <select v-model="rUXA.u011[i-1]">
                          <option v-for="item in laTiposAgrupacionUXA" :key="item.c" :value="item.c">{{ item.d }}</option>
                        </select>
                      </td>
                      <td align="center"><input type="text" v-model="rUXA.u012[i-1]" size="8" /></td>
                    </tr>
                  </table>
                </div>

                <div class="col-md-3">
                  <table class="igs-fs-10" style="width:98%" border="0">
                    <tr>
                      <td align="center">Nro.</td>
                      <td align="center">Tipo Agrupación</td>
                      <td align="center">Código</td>
                    </tr>
                    <tr v-for="i in 10" :key="i">
                      <td align="center">{{i+10}}</td>
                      <td align="center">
                        <select v-model="rUXA.u011[i-1+10]">
                          <option v-for="item in laTiposAgrupacionUXA" :key="item.c" :value="item.c">{{ item.d }}</option>
                        </select>
                      </td>
                      <td align="center"><input type="text" v-model="rUXA.u012[i-1+10]" size="8" /></td>
                    </tr>
                  </table>
                </div>

                <div class="col-md-3">
                  <table class="igs-fs-10" style="width:98%" border="0">
                    <tr>
                      <td align="center">Nro.</td>
                      <td align="center">Tipo Agrupación</td>
                      <td align="center">Código</td>
                    </tr>
                    <tr v-for="i in 10" :key="i">
                      <td align="center">{{i}}</td>
                      <td align="center">
                        <select v-model="rUXA.u013[i-1]">
                          <option v-for="item in laTiposAgrupacionUXA" :key="item.c" :value="item.c">{{ item.d }}</option>
                        </select>
                      </td>
                      <td align="center"><input type="text" v-model="rUXA.u014[i-1]" size="8" /></td>
                    </tr>
                  </table>
                </div>

                <div class="col-md-3">
                  <table class="igs-fs-10" style="width:98%" border="0">
                    <tr>
                      <td align="center">Nro.</td>
                      <td align="center">Tipo Agrupación</td>
                      <td align="center">Código</td>
                    </tr>
                    <tr v-for="i in 10" :key="i">
                      <td align="center">{{i+10}}</td>
                      <td align="center">
                        <select v-model="rUXA.u013[i-1+10]">
                          <option v-for="item in laTiposAgrupacionUXA" :key="item.c" :value="item.c">{{ item.d }}</option>
                        </select>
                      </td>
                      <td align="center"><input type="text" v-model="rUXA.u014[i-1+10]" size="8" /></td>
                    </tr>
                  </table>
                </div>
              </div>            
          </div>

          <br/>

        </div>
      </VModal>

      <!-- Ventana Modal .3. (Edición CYR) -->
      <VModal name="VMLeyenda3" height="auto" @before-close="lp99OcultandoLeyenda3()" :draggable="true" :adaptive="true" :resizable="true">
        <div class="igs-back-color8" style="height:100%;">
          <div class="row">
            <div class="col-md-6 igs-back-color2"><h6 class="igs-color0 igs-center igs-pad-2">Caminos y Recorridos</h6></div>
            <div class="col-md-2 igs-color0 igs-back-color2 igs-fs-12 igs-pad-4" @click="lp60BorrarCYR()">&nbsp;&nbsp;<Fa :icon="faTrash" /> Borrar</div>              
            <div class="col-md-2 igs-color0 igs-back-color2 igs-fs-12 igs-pad-4" @click="lp60ActualizarCYR(); $modal.hide('VMLeyenda3')">&nbsp;&nbsp;<Fa :icon="faSave" /> Guardar</div>              
            <div class="col-md-2 igs-color0 igs-back-color2 igs-l3" @click="$modal.hide('VMLeyenda3')"><Fa :icon="faWindowClose" /></div>
          </div>

          <div>&nbsp;</div>

          <div class="row igs-left igs-pad-6">
            <div class="col-md-5">Descripción</div>
            <div class="col-md-7 igs-fs-11"><input type="text" v-model="rCYR.y003" size="32" /></div>
          </div>

          <div class="row igs-left igs-pad-6">
            <div class="col-md-5">Color</div>
            <div class="col-md-7 igs-fs-11">
                <!-- <span :style="lf91EstiloColor('#'+rCYR.y015)">&nbsp;&nbsp;&nbsp;&nbsp;</span> -->
                <v-swatches style="transform: scale(0.5); height:20px; position:relative; top:-10px;" swatches="text-advanced" shapes="rectangle" popover-x="top" swatch-size="32" v-model="rCYR.y015"></v-swatches>
            </div>
          </div>

          <div class="row igs-left igs-pad-6">
            <div class="col-md-5">Ubicación Inicial</div>
            <div class="col-md-7 igs-fs-11"><input type="text" v-model="rCYR.y011" size="14" /></div>
          </div>
          
          <div class="row igs-left igs-pad-6">
            <div class="col-md-5">Ubicación Final</div>
            <div class="col-md-7 igs-fs-11"><input type="text" v-model="rCYR.y012" size="14" /></div>
          </div>
          
          <div>&nbsp;</div>

          <div class="row igs-left igs-pad-6">
            <div class="col-md-5">Posición</div>
            <div class="col-md-7 igs-fs-11">
              <select v-model="rCYR.y013">
                <option value="A">Arriba</option>
                <option value="B">Abajo</option>
                <option value="D">Derecha</option>
                <option value="I">Izquierda</option>
                <option value="V">Zig-Zag (Vertical)</option>
                <option value="Z">Zig-Zag (Horizontal)</option>
              </select>
            </div>
          </div>

          <div class="row igs-left igs-pad-6">
            <div class="col-md-5">Zona de Carga</div>
            <div class="col-md-7 igs-fs-11">
              <select v-model="rCYR.y014">
                <option value="">Indefinida</option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="D">D</option>
                <option value="E">E</option>
                <option value="F">F</option>
                <option value="G">G</option>
                <option value="H">H</option>
                <option value="I">I</option>
                <option value="J">J</option>
                <option value="K">K</option>
                <option value="L">L</option>
                <option value="M">M</option>
                <option value="N">N</option>
                <option value="O">O</option>
                <option value="P">P</option>
                <option value="Q">Q</option>
                <option value="R">R</option>
                <option value="S">S</option>
                <option value="T">T</option>
                <option value="U">U</option>
                <option value="V">V</option>
                <option value="W">W</option>
                <option value="X">X</option>
                <option value="Y">Y</option>
                <option value="Z">Z</option>
              </select>
            </div>
          </div>

          <div class="row igs-left igs-pad-6">
            <div class="col-md-5">Índice Impresora Etiquetas</div>
            <div class="col-md-7 igs-fs-11">
              <select v-model="rCYR.y016">
                <option value=0>Predeterminada</option>
                <option value=1>1</option>
                <option value=2>2</option>
                <option value=3>3</option>
                <option value=4>4</option>
                <option value=5>5</option>
                <option value=6>6</option>
                <option value=7>7</option>
                <option value=8>8</option>
                <option value=9>9</option>
              </select>
            </div>
          </div>

          <div class="row igs-left igs-pad-6">
            <div class="col-md-5">Nuevo Palé cada ... <code style="font-size:8px">(Nº Palés)</code></div>
            <div class="col-md-7 igs-fs-11">
              <div class="col-md-7 igs-fs-11"><input type="text" v-model="rCYR.y017" size="14" /></div>
            </div>
          </div>
          
          <div>&nbsp;</div>

        </div>
      </VModal>

      <!-- Ventana Modal .11. (Filtros Visualización Mapa) -->
      <VModal name="VMLeyenda11" height="auto" @before-close="lp99OcultandoLeyenda11()" :draggable="true" :adaptive="true" :resizable="true">
        <div class="igs-back-color8" style="height:100%;">
          <div class="row">
            <div class="col-md-11 igs-back-color1"><h5 class="igs-color0 igs-center">Filtros Consulta Mapa</h5></div>
            <div class="col-md-1 igs-color0 igs-back-color1 igs-l3" @click="$modal.hide('VMLeyenda11')"><Fa :icon="faWindowClose" /></div>
          </div>

          <div class="row igs-pad-4">
            <div class="col-md-4 igs-left igs-l4">&nbsp;</div>
            <div class="col-md-8 igs-left igs-l4">&nbsp;</div>
          </div>

          <div class="row igs-pad-4">
            <div class="col-md-4 igs-left igs-l4">Mostrar Huecos ...</div>
            <div class="col-md-8 igs-left igs-l4">
              <select class="igs-select-sm" v-model="lnFiltroMapaActividad" @change="lp40FiltrarHuecosMapa()">
                <option value=0>De Todo Tipo</option>
                <option value=1>Huecos SIN Actividad en Curso</option>
                <option value=2>Huecos CON Actividad en Curso</option>
              </select>            
            </div>
          </div>

          <div class="row igs-pad-4">
            <div class="col-md-4 igs-left igs-l4">Espacio Ocupado ...</div>
            <div class="col-md-8 igs-left igs-l4">
              <select class="igs-select-sm" v-model="lnFiltroMapaPorcentaje" @change="lp40FiltrarHuecosMapa()">
                <option value=-1>Todos</option>
                <option value=0>Negativo</option>
                <option value=1>Cero</option>
                <option value=2>0,01% al 33,33%</option>
                <option value=3>33,34% al 66,66%</option>
                <option value=4>66,66% al 99,99%</option>
                <option value=5>100%</option>
                <option value=6>> 100%</option>
              </select>            
            </div>
          </div>

          <div class="row igs-pad-4">
            <div class="col-md-4 igs-left igs-l4">&nbsp;</div>
            <div class="col-md-8 igs-left igs-l4">&nbsp;</div>
          </div>

          <div class="row igs-pad-4">
            <div class="col-md-12 igs-center igs-l4"><button class="igs-btn-primary ml-2" style="width:140px" @click="$modal.hide('VMLeyenda11');lp90RefrescarMapa(0);">Aplicar Filtros</button></div>
          </div>

          <div class="row igs-pad-4">
            <div class="col-md-4 igs-left igs-l4">&nbsp;</div>
            <div class="col-md-8 igs-left igs-l4">&nbsp;</div>
          </div>

        </div>
      </VModal>


  </div>
</template>

<script>
import igs_mixin from '@/igs-mixins/igs_mixin'
import Multiselect  from 'vue-multiselect'

import TNC10000  from '@/igs-navegacion/TNC10000'
import BNC10000  from '@/igs-navegacion/BNC10000'
import SVC11190  from '@/igs-vistas/SVC11190'

import Fa from 'vue-fa'
import { faPlusSquare, faFile, faEdit, faSave, faTrash, faWindowClose, faSearch, faCheckSquare, faCircle, faQuestionCircle, faInfo, faClock } from '@fortawesome/free-solid-svg-icons'

import VueDragResize from 'vue-drag-resize';
import axios         from 'axios';

//-- Rango de Fechas
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

//-- Datos
export default {
  mixins:       [ igs_mixin ],
  components :  { TNC10000, BNC10000, SVC11190, VueDragResize, Fa, DateRangePicker, Multiselect },

  //----------------------------
  //-- Datos Locales
  //----------------------------
  data() {
    //-- Valores Predeterminado
    let startDate = new Date();
    let endDate   = new Date();
    startDate.setDate(endDate.getDate() - 6);

    let today = new Date()
    today.setHours(0, 0, 0, 0)

    let yesterday = new Date()
    yesterday.setDate(today.getDate() - 1)
    yesterday.setHours(0, 0, 0, 0);

    var thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    var thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 11, 59, 59, 999);

    return {

      //------------------------------------------------------------------------
      //-- Iconos
      //------------------------------------------------------------------------
      faPlusSquare, 
      faFile,
      faEdit,
      faSave,
      faTrash,
      faWindowClose,
      faSearch,
      faCheckSquare,
      faCircle,
      faQuestionCircle,
      faInfo,
      faClock,


       //------------------------------------------------------------------------
       //-- Variables Locales
       //------------------------------------------------------------------------
      lSWMostrarHerramientasDibujo : false,                 //-- Mostrar Herramientas de Dibujo (Redimensionar, Mover Hueco, ...)
      lSWMostrarTooltip : false,                            //-- Mostrar/No Mostrar Tooltip con datos del Hueco
      lSWMostrarReticula : true,                            //-- Mostrar/No Mostrar Retícula

      lbMostrarActividadPasadaCerrada : false,              //-- Mostrar Actividad Pasada (Cerrada) en el Detalle de Movimientos Recientes

      laListaPasillos: [],                                  //-- Lista de Pasillos disponibles
      laListaDemarcaciones: [],                             //-- Lista Demarcaciones Disponibles
      ltDemarcacion:'01',                                   //-- Demarcacion Actual
      laHuecosMapa: [],                                     //-- Lista Viva con los HUECOS del Mapa
      lnContadorHueco : 0,                                  //-- Contador para NUEVO Hueco
      ltIdHuecoActivo: '',                                  //-- ID del Hueco Activo
      ltArticuloHuecoActivo: '',                            //-- Código de Artículo del Hueco Activo
      ltTipoHuecoActivo: '0',                               //-- Tipo de Hueco del Hueco Activo
      ltNroPalesHuecoActivo : '1',                          //-- Número de Palés Almacenables en el Hueco Activo
      lnIndiceUltimoHuecoActivo : -1,                       //-- Indice del ÚLTIMO HUECO ACTIVO
      lbModoAlzada : false,                                 //-- Modo Alzada / Planta

      lnFiltroMapaActividad : 0,                            //-- Tipo de Hueco (según el Contenido)
      lnFiltroMapaPorcentaje : -1,                          //-- Porcentajes de Ocupación a Mostrar

      lnTipoEdicion : 0,                                   //-- Tipo de Edición
      lbPanelDerechoAbierto : false,                       //-- Panel Derecho Abierto/Cerrado

      ltMapaPasillo : '11',                                 //-- Número de Pasillo a mostrar en el Mapa (Modo Planta)
      ltMapaAltura : '10',                                  //-- (Altura) a mostrar (Modo Alzada)
      lnMapaEscala : 1.0,                                   //-- Escala para el Mapa      

      lnZoomX : 1.2,                                        //-- Multiplicador X (Zoom)
      lnZoomY : 1.4,                                        //-- Multiplicador Y (Zoom)

      lnMapaOffsetY : 32,                                   //-- Pixels Desplazamiento Vertical (para cada hueco)

      laRegistrosMPA : [],                                  //-- Lista de Huecos recibida del servidor
      laRegistrosBAM: [],                                   //-- Lista de Huecos Obtenidos en la Búsqueda de Artículos (BAM = Búsqueda Artículos en Mapa)

      laRegistrosMET: [],                                   //-- Lista de Movimientos en Tránsito (Pendientes de Confirmación) filtrada localmente y mostrada al usuario
      laRegistrosMET_Completa: [],                          //-- Lista de Movimientos en Tránsito (Pendientes de Confirmación) recibida del Servidor
      lnOrdenRegistrosMET : 0,                              //-- Criterio Ordenación Registros MET
      lnFiltroTMRegistrosMET : 0,                           //-- Filtro a aplicar en los registros MET
      lbMarcarDesmarcarMET : false,                         //-- ChekcBox Marcar/Desmarcar TODOS los registros MET
      ltFiltroTextoMET : '',                                //-- Valor Texto a Filtrar en Búsquedas MET

      ltFiltroOPERegistrosMET : '-- Todos --',              //-- Valor a Filtrar en los Operarios (Almaceneros) de los Movimientos en Tránsito (MET)
      laListaOperariosMET : ['-- Todos --'],                //-- Lista de Operarios a filtrar

      lbCambioArticuloBusquedaEnCurso : false,              //-- ¿ Está siendo editado el CÓDIGO DE ARTÍCULO a Buscar ?
      ltHuecoResaltado : '?',                               //-- Ubicación a Resaltar
      ltArticuloBusquedaCodigo : '',                        //-- Código del Artículo a Buscar
      ltArticuloBusquedaDescripcion : '',                   //-- Descripción del Artículo a Buscar
      ltArticuloBusquedaFormato : '',                       //-- Formato (Minimo de Venta) del Artículo a Buscar
      loArticuloBusqueda : {},                              //-- Objeto del Artículo a Buscar

      laSentidoCamino : [ ],

      lSW11190_Visible : false,

      rCYR : {},
                                                            //-- Registro UXA a Editar
      rUXA : { "u001" : "",  "u002" : "", "u003" : 0,
               "u004" : "S", "u005" : "", "u006" : "", "u007":"", 

               "u011" : ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0"],
               "u012" : ["",  "",  "",  "",  "",  "",  "",  "",  "",  "" , "",  "",  "",  "",  "",  "",  "",  "",  "",  "" ],

               "u013" : ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0", "0"],
               "u014" : ["",  "",  "",  "",  "",  "",  "",  "",  "",  "" , "",  "",  "",  "",  "",  "",  "",  "",  "",  "" ],

               "u021" : "",        "u022" : "", "u023" : "", "u031": "",
               "u041" : "#000000", "u042" : "", "u043" : "", "u044" : "", "u045" : "", "u046" : "",

               "u101" : "", "u102" : "", "u103" : "", "u104" : "", "u105" : "", "u106" : "", 
               "u201" : "", "u202" : "", "u203" : "", "u204" : "", "u205" : "", "u206" : ""  },  

      laRegistrosUXA: [],                                   //-- Lista de Registros UXA disponibles
      laUXASeleccionados : [],                              //-- Lista de registros UXA Seleccionados
      laTiposAgrupacionUXA : [                              //-- Tipos de (Agrupación Artículo) disponibles
        { "c" : "0", "d" : "-- Sin Uso --"        },
        { "c" : "1", "d" : "Artículo"             },
        { "c" : "2", "d" : "Fam.Contable"     },
        { "c" : "3", "d" : "Fam.Estadística"  },
        { "c" : "4", "d" : "Grupo Producto"    },
        { "c" : "5", "d" : "Proveedor"            },
        { "c" : "6", "d" : "Fabricante"           },
        { "c" : "7", "d" : "Sección"              }
      ],

      ltTipoCYR : "0",
      laRegistrosCYR : [
      ],

      lrRefreshTimer: null,
      lnSegundosTimer : 0,

      ltFechaInicialDetalleMovimientos : '00000000',      //-- Valor (00000000) : Solo Movimientos Pendientes de Cierre
                                                          //-- (Otro Valor) ....: Detalle desde la (Fecha Indicada)

      dp1_dateRange: {startDate, endDate},
      dp1_localeData : {
            direction: 'ltr',
            format: 'dd-mm-yyyy',
            separator: ' a ',
            applyLabel: 'Confirmar',
            cancelLabel: 'Cancelar',
            weekLabel: 'W',
            customRangeLabel: 'Rango',
            daysOfWeek: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
            monthNames: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            firstDay: 1
        },
        dp1_defaultRanges : {
            'Hoy': [today, today],
            'Ayer': [yesterday, yesterday],
            'Mes Actual': [thisMonthStart, thisMonthEnd],
            'Mes Anterior': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
            'Año Actual': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)]
        },

    }
  },

  //----------------------------
  //-- Métodos Locales
  //----------------------------
  methods: {

    //----------------------------------------------------------------------
    //-- Obtener la Posición (X,Y) más cercana a la posición suministrada
    //----------------------------------------------------------------------
    lf30UbicacionXY(ubicacion, desplazamiento) {

      //-- Valores Predeterminados
      let valorX = 1;
      let valorY = 1;
      let valorS = 'I';

      //-- Desplazamiento X
      let offsetX = 0;
      if (ubicacion.substr(4,2) >= '98') { offsetX = -40 * this.lnZoomY * this.lnMapaEscala; }

      //-- Desplazamiento Y
      let offsetY = 0;
      if (desplazamiento == 'A') { offsetY = -16 * this.lnZoomY * this.lnMapaEscala; }
      if (desplazamiento == 'B') { offsetY =  48 * this.lnZoomY * this.lnMapaEscala; }

      //-- Localizar el valor más próximo

      for (const r of this.laHuecosMapa) {

        //-- Cuando hay coincidencia de DEMARCACIÓN Y PASILLO
        if (ubicacion.substr(0,4) == r.id.substr(0,4)) {
          //--  .........  Ubicación INICIAL  ..............         .....  Ubicación FINAL ......
          if (((ubicacion.substr(4,2) <= '01') && (valorX == 1)) || (ubicacion.substr(4,2) >= '98')) {
            valorX = r.left + offsetX;
            valorY = r.top + offsetY;
          }
        }
      }

      //-- Retornar el valor encontrado
      return { x:valorX, y:valorY, s:valorS };
    },

    //----------------------------------------------------------------------
    //-- Estilo para la Etiqueta VERTICAL (Izquierda del Mapa) [Modo Alzada]
    //----------------------------------------------------------------------
    lf30PosicionLabelAlzadaAltura(x,y) {
      let posicionX = (x - 240) * this.lnMapaEscala;
      if (this.$store.state.strDistribuidor == 'D070001') { posicionX = (x - 420) * this.lnMapaEscala; }

      let posicionY = 5 * this.lnMapaEscala;
      let ancho = 50;
      let alto = 40;
      this.p99Log(5,1,'X:'+x+'Y:'+y);
      return "position:absolute; left:"+posicionX+"px; top:"+posicionY+"px; width:"+ancho+"px; height:"+alto+"px";
    },

    //--------------------------------------------------------------------------
    //-- Estilo para la Etiqueta HORIZONTAL (Encabezado del Mapa) [Modo Alzada]
    //--------------------------------------------------------------------------
    lf30PosicionLabelAlzadaCalle(x,y,index) {
      let posicionX = 10 * this.lnMapaEscala;
      let posicionY = (y - (window.innerHeight * 1.02)) * this.lnMapaEscala; 
      //if (index == 0) { posicionY = (y - 148); }

      let ancho = 72 * this.lnMapaEscala;
      let alto = 24;
      this.p99Log(5,1,'X:'+x+'  Y:'+y+'  index:'+index);
      return "position:relative; left:"+posicionX+"px; top:"+posicionY+"px; width:"+ancho+"px; height:"+alto+"px;";
    },


    //----------------------------------------------------------------------
    //-- Estilo para la Etiqueta HORIZONTAL (Pie del Mapa) [Modo Alzada]
    //----------------------------------------------------------------------
    lf30PosicionLabelAlzadaPasillo(x,y) {
      let posicionX = 320 * this.lnMapaEscala;
      let posicionY = 110 * this.lnMapaEscala;
      let ancho = 250;
      let alto = 32;
      this.p99Log(5,1,'X:'+x+'Y:'+y);
      return "position:absolute; left:"+posicionX+"px; top:"+posicionY+"px; width:"+ancho+"px; height:"+alto+"px";
    },

    //----------------------------------------------------------------------
    //-- Estilo para la Etiqueta VERTICAL (Izquierda del Mapa) [Modo Planta]
    //----------------------------------------------------------------------
    lf30PosicionLabelPlantaPasillo(x,y,index) {
      let posicionX = x - (220 * this.lnMapaEscala);
      let posicionY = 10 * this.lnMapaEscala;
      let ancho = 72 * this.lnMapaEscala;
      let alto = 28;
      this.p99Log(5,1,'X:'+x+'  Y:'+y+'  index:'+index);
      return "position:relative; left:"+posicionX+"px; top:"+posicionY+"px; width:"+ancho+"px; height:"+alto+"px;";
    },

    //--------------------------------------------------------------------------
    //-- Estilo para la Etiqueta HORIZONTAL (Encabezado del Mapa) [Modo Planta]
    //--------------------------------------------------------------------------
    lf30PosicionLabelPlantaCalle(x,y,index) {
      let posicionX = 10 * this.lnMapaEscala;
      let posicionY = (y - 120);
      if (index == 0) { posicionY = (y - 148); }

      let ancho = 72 * this.lnMapaEscala;
      let alto = 24;
      this.p99Log(5,1,'X:'+x+'  Y:'+y+'  index:'+index);
      return "position:relative; left:"+posicionX+"px; top:"+posicionY+"px; width:"+ancho+"px; height:"+alto+"px;";
    },

    //.....................................................................................
    //-- Conversión (Date) en (String) de la Fecha Inicial para el Detalle de Movimientos
    //.....................................................................................
    lp30FechaInicialDetalleMovimientos() {
      //-- Extraer Fecha INICIAL
      let startDate       = this.dp1_dateRange.startDate;
                            //startDate.setHours(0,0,0,0);
      let fechaInicial = this.f92Date2DTZ(startDate).substr(0,10).replace(/-/g,'');

      //-- CheckBox DESMARCADA : Mostrar ÚNICAMENTE movimentos PENDIENTES DE CIERRE
      if (!this.lbMostrarActividadPasadaCerrada) { fechaInicial = '00000000'; }

      //-- Retornar el valor calculado
      //console.log(' fechaInicial = ('+fechaInicial+')');
      this.ltFechaInicialDetalleMovimientos = fechaInicial;
    },

    //----------------------------------------------------------------------
    //-- Clase a aplicar en las FLECHAS que muestran la SENDA del camino
    //----------------------------------------------------------------------
    lf50ClaseCamino(sentido) {

      var imagen = '';
      var flecha = '';

      switch (sentido) {
        case 'A':                                   //-- Arriba
          imagen = 'fa-long-arrow-up';
          flecha = 'arrowA';                      
          break;

        case 'B':                                   //-- Abajo
          imagen = 'fa-long-arrow-down';                        
          flecha = 'arrowB';                      
          break;

        case 'I':                                   //-- Izquierda
          imagen = 'fa-long-arrow-left';                        
          flecha = 'arrowI';                      
          break;

        case 'D':                                   //-- Derecha
          imagen = 'fa-long-arrow-right';                        
          flecha = 'arrowD';                      
          break;

        case 'V':                                   //-- Zig-Zag (Vertical)
          imagen = 'fa-wave-square';                        
          flecha = 'arrowB';                      
          break;

        case 'Z':                                   //-- Zig-Zag (Horizontal)
          imagen = 'fa-wave-suare';                        
          flecha = 'arrowD';                      
          break;

        default :                                   //-- Interrogación
          imagen = 'fa-question';
          flecha = 'arrowD';                      
          break;
      }

      //-- Retornar la Clase COMPLETA
      return "fa "+ imagen + " " + flecha + " igs-color1";


      /*
            <span class="fa fa-long-arrow-up    arrowA" aria-hidden="true"></span>
            &nbsp;&nbsp;
            <span class="fa fa-long-arrow-down  arrowB" aria-hidden="true"></span>
            &nbsp;&nbsp;&nbsp;
            <span class="fa fa-long-arrow-left  arrowI" aria-hidden="true"></span>
            &nbsp;
            <span class="fa fa-long-arrow-right arrowD" aria-hidden="true"></span>
            &nbsp;
            <span class="fa fa-wave-square arrowD" aria-hidden="true"></span>
            &nbsp;
            <span class="fa fa-wave-square arrowB" aria-hidden="true"></span>
        */


    },

    //----------------------------------------------------------------------
    //-- Ordenar Lista de Movimientos en Tránsito (MET)
    //----------------------------------------------------------------------
    lp50OrdenarRegistrosMET() {

      //-- Crear nueva lista con el Orden seleccionado
      let self = this;
      let listaOrdenada = this.laRegistrosMET.sort(function(a, b){

        //-- Ubicación
        if (self.lnOrdenRegistrosMET == 0) {
               if (a.m001 < b.m001) { return -1; }
               if (a.m001 > b.m001) { return  1; }
               return 0; 
        }

        //-- Artículo
        if (self.lnOrdenRegistrosMET == 1) {
              if (a.m022 < b.m022) { return -1; }
              if (a.m022 > b.m022) { return  1; }
              return 0; 
        }

        //-- Fecha/Hora
        if (self.lnOrdenRegistrosMET == 2) {
              if (a.mdma[0].d083 + ' ' + a.mdma[0].d084 < b.mdma[0].d083 + ' ' + b.mdma[0].d084) { return -1; }
              if (a.mdma[0].d083 + ' ' + a.mdma[0].d084 > b.mdma[0].d083 + ' ' + b.mdma[0].d084) { return  1; }
              return 0; 
        }

        //-- Resto de Casos
        return 0;
      });

      //-- Reemplazar Lista de Resultados                                              
      this.laRegistrosMET = listaOrdenada;

    },

    //----------------------------------------------------------------------
    //-- Al seleccionar un hueco para su manipulación
    //----------------------------------------------------------------------
    lp60ConsultarHueco(index) {
      this.p99Log(1,1,'lp60ConsultarHueco - '+index);

      this.lnIndiceUltimoHuecoActivo = index;
      if (this.laHuecosMapa[index].id.substr(0,1) != '_') {
        this.ltIdHuecoActivo           = this.laHuecosMapa[index].id;
        this.ltArticuloHuecoActivo     = this.laHuecosMapa[index].articulo;
        this.ltTipoHuecoActivo         = this.laHuecosMapa[index].tipoHueco;
        this.ltNroPalesHuecoActivo     = this.laHuecosMapa[index].nroPales;
      }

      //-- Mostrar PopUp con la información del Hueco Seleccionado
      if (!this.lSWMostrarHerramientasDibujo) { this.lp90Mostrar11190();  }
    },

    //----------------------------------------------------------------------
    //-- Al seleccionar un hueco para su manipulación
    //----------------------------------------------------------------------
    lp60ConsultarUbicacionBAM(indice) {

      //-- Identificar Hueco a Resaltar
      this.ltHuecoResaltado = this.laRegistrosBAM[indice].m001;

      //-- Determinar la correspondencia del (Hueco Resaltado) con la (Lista de Ubicaciones Completa)
      console.log(' Buscando Hueco '+this.ltHuecoResaltado);
      var i1 = this.laHuecosMapa.findIndex( item => (item.id.substr(0,10) == this.ltHuecoResaltado.substr(0,10)) );

      //-- Si lo hemos encontrado : Mostrar Resultado
      if (i1 >= 0) {
        this.lnIndiceUltimoHuecoActivo = i1;
        if (this.laHuecosMapa[i1].id.substr(0,1) != '_') {
          this.ltIdHuecoActivo           = this.laHuecosMapa[i1].id;
          this.ltArticuloHuecoActivo     = this.laHuecosMapa[i1].articulo;
          this.ltTipoHuecoActivo         = this.laHuecosMapa[i1].tipoHueco;
          this.ltNroPalesHuecoActivo     = this.laHuecosMapa[i1].nroPales;
        }

        //-- Mostrar Popup con la información del Hueco Seleccionado
        if (!this.lSWMostrarHerramientasDibujo) { this.lp90Mostrar11190(); }
      }

    },

    //----------------------------------------------------------------------
    //-- Al seleccionar un hueco para su manipulación
    //----------------------------------------------------------------------
    lp60ActivarHueco(index) {
      this.p99Log(5,1,'lp60ActivarHueco - '+index);
      this.lnIndiceUltimoHuecoActivo = index;
      if (this.laHuecosMapa[index].id.substr(0,1) != '_') {
        this.ltIdHuecoActivo           = this.laHuecosMapa[index].id;
        this.ltArticuloHuecoActivo     = this.laHuecosMapa[index].articulo;
        this.ltTipoHuecoActivo         = this.laHuecosMapa[index].tipoHueco;
        this.ltNroPalesHuecoActivo     = this.laHuecosMapa[index].nroPales;
      }
    },

    //----------------------------------------------------------------------
    //-- Al cambiar de (Demarcación)
    //----------------------------------------------------------------------
    lp60ActualizarDemarcacion() {

      //-- Actualizar Demarcación
      this.$store.commit('p10StrAlmacenDemarcacion', this.ltDemarcacion);

      //-- Actualizar Lista de Pasillos
      this.lp98ListaPasillos();

      //-- Lectura del Mapa a mostrar
      this.lp98LeerMapa('');

      //-- Reclamar contenido a mostrar
      if (this.lnTipoEdicion == 91) {  this.lp98LeerListaMET(); }

    },   

    //----------------------------------------------------------------------
    //-- Al De-Seleccionar un hueco para su manipulación
    //----------------------------------------------------------------------
    lp60DesactivarHueco(index) {
      this.p99Log(5,1,'lp60DesactivarHueco - '+index);
    },
    
    //----------------------------------------------------------------------
    //-- Trasladar ID Hueco al ÚLTIMO HUECO ACTIVO
    //----------------------------------------------------------------------
    lp60ActualizarIdHueco() {
      this.p99Log(5,1,'Actualizando Hueco '+this.lnIndiceUltimoHuecoActivo);

      if (this.lnIndiceUltimoHuecoActivo > -1) {

        //-- Actualizar valores en el Array Local
        this.laHuecosMapa[this.lnIndiceUltimoHuecoActivo].id        = this.ltIdHuecoActivo;
        this.laHuecosMapa[this.lnIndiceUltimoHuecoActivo].articulo  = this.ltArticuloHuecoActivo;
        this.laHuecosMapa[this.lnIndiceUltimoHuecoActivo].tipoHueco = this.ltTipoHuecoActivo;
        this.laHuecosMapa[this.lnIndiceUltimoHuecoActivo].nroPales  = this.ltNroPalesHuecoActivo;
        this.laHuecosMapa[this.lnIndiceUltimoHuecoActivo].color     = global.lnkDefaultMPAColors[10];        

      }
    },

    //----------------------------------------------------------------------
    //-- Actualizar Caminos y Recorridos (CYR)
    //----------------------------------------------------------------------
    lp60ActualizarCYR() {

      this.p99Log(5,1,'lp60ActualizarCYR');

      this.p99Log(5,1,'-- qCYR --');
      var qCYR = '';
      var i    = 0;

      //-- AGREGAR un registro ({}) por cada HUECO, separando cada ATRIBUTO con la virgulilla (~)
      for (const r of this.laRegistrosCYR) {
        qCYR = qCYR + r.y001 + '~' + i + '~'  + r.y003 + '~' + r.y011 + '~' + r.y012 + '~' + r.y013 + '~' + r.y014 + '~' + r.y015 + '~' + r.y016 + '~' + r.y017 + '^';
        i += 1;
      }

      //-- Debug
      this.p99Log(1,1,'-- qCYR --');
      this.p99Log(1,1,qCYR,qCYR.length);

      //-- Llamada al Servidor
      var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=931&x=" + encodeURIComponent(qCYR);

      //-- Contactar con el Servidor del Distribuidor
      axios.get(URL, {responseEncoding: 'utf-8'})                         
      .then(respuesta => {

          //-- Extraer la respuesta recibida
          var datos = respuesta.data;

          //-- Determinar si ha habido EXITO ó ERROR
          var flg = global.lnkGetDataError;
          if (datos.flg) {  flg = datos.flg; }

          //-- Actualizar Resultados
          this.p99Log(5,1,'-- Respuesta (200931) --');
          this.p99Log(5,2,datos);

          //-- Evaluar respuesta recibida
          if (flg == global.lnkGetDataOk)  {
            this.p99Log(1,1,' **** EXITO ****');
            this.p99AnuncioIA1('1','7','¡ Éxito !', 'La lista de CAMINOS Y RECORRIDOS ha sido actualizado correctamente.',3);

            //-- Re-Lectura del Mapa a mostrar
            this.lp98LeerListaCYR();
          } else {
            this.p99Log(1,1,' #### ERROR (200931) ####');
            if (datos.msg) { this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1); } else { this.p99AnuncioIA1('2','7','ERROR', respuesta.data,-1); }
          }
      })
      .catch(error => {
          //-- Mostrar el Mensaje de Error oportuno
          this.p99Log(1,1,'** ERROR **');
          this.p99Log(1,1,error);
      });

    },


    //----------------------------------------------------------------------------
    //-- Filtrar Lista de Movimientos en Tránsito (MET) 
    //-- (por Tipo de Movimiento, Almacenero, y FiltroTexto)
    //----------------------------------------------------------------------------
    lp60FiltrarRegistrosMET() {

      //-- Array Temporal
      let laRegistrosMETFiltrados = [];

      //-- Iniciar Bucle
      let i = 0;
      let swTM  = false;
      let swTXT = false;
      let swOPE = false;

      while (i < this.laRegistrosMET_Completa.length) {

        //.........................................
        //-- Filtro por TIPO DE MOVIMIENTO
        //.........................................
        swTM = false;

        //-- Todos
        if (this.lnFiltroTMRegistrosMET == 0) { swTM = true; }

        //-- Compra
        if ((this.lnFiltroTMRegistrosMET == 1) && (this.laRegistrosMET_Completa[i].mdma[0].d011 >= "10") && (this.laRegistrosMET_Completa[i].mdma[0].d011 <= "19")) {
          swTM = true;
        }

        //-- Venta
        if ((this.lnFiltroTMRegistrosMET == 2) && (this.laRegistrosMET_Completa[i].mdma[0].d011 >= "20") && (this.laRegistrosMET_Completa[i].mdma[0].d011 <= "29")) {
          swTM = true;
        }

        //-- Movimientos entre Almacenes
        if ((this.lnFiltroTMRegistrosMET == 3) && (this.laRegistrosMET_Completa[i].mdma[0].d011 >= "30") && (this.laRegistrosMET_Completa[i].mdma[0].d011 <= "39")) {
          swTM = true;
        }

        //-- Recuento
        if ((this.lnFiltroTMRegistrosMET == 4) && (this.laRegistrosMET_Completa[i].mdma[0].d011 >= "40") && (this.laRegistrosMET_Completa[i].mdma[0].d011 <= "49")) {
          swTM = true;
        }

        //-- Otros
        if ((this.lnFiltroTMRegistrosMET == 9) && ((this.laRegistrosMET_Completa[i].mdma[0].d011 < "10") || (this.laRegistrosMET_Completa[i].mdma[0].d011 > "49"))) {
          swTM = true;
        }

        //.........................................
        //-- Filtro por TEXTO
        //.........................................
        swTXT = false;

        if (this.ltFiltroTextoMET == '') {
          swTXT = true;
        } else {
          let posicion = this.laRegistrosMET_Completa[i].mdma[0].d014.search(this.ltFiltroTextoMET);
          if (posicion >= 0) { swTXT = true; }
        }

        //.........................................
        //-- Filtro por TIPO DE MOVIMIENTO
        //.........................................
        swOPE = false;

        //-- Todos
        if (this.ltFiltroOPERegistrosMET == '-- Todos --') { swOPE = true; }

        //-- Operario Específico
        if ((this.ltFiltroOPERegistrosMET != '-- Todos --') && (this.laRegistrosMET_Completa[i].mdma[0].d082 == this.ltFiltroOPERegistrosMET)) { swOPE = true; }

        //............................................................
        //-- ¿ Registro Compatible ? --> Incorporar a Lista Filtrada
        //............................................................
        this.p99Log(5,1,'  swTM : '+swTM+'  swTXT : '+swTXT+'  swOPE : '+swOPE);
        if ((swTM) && (swTXT) && (swOPE)) {  laRegistrosMETFiltrados.push(this.laRegistrosMET_Completa[i]);  }
        
        //.................
        //-- Siguiente
        //.................
        i += 1;
      }

      //-- Construir Lista de Operarios ÚNICOS
      this.laListaOperariosMET = [...new Set(laRegistrosMETFiltrados.map(item => item.mdma[0].d082))];
      this.laListaOperariosMET.unshift('-- Todos --');

      //-- Trasladar Lista LOCAL a Lista GLOBAL (a fin de mostrar al usuario)
      this.p99Log(5,1,'-- laRegistrosMETFiltrados : '+laRegistrosMETFiltrados.length+' // laRegistrosMET_Completa : '+this.laRegistrosMET_Completa.length);
      this.laRegistrosMET = laRegistrosMETFiltrados;

      //-- Ordenar Resultados
      this.lp50OrdenarRegistrosMET();
    },

    //----------------------------------------------------------------------
    //-- Creación de Nuevo Registro CYR
    //----------------------------------------------------------------------
    lp60NuevoCYR() {
      this.laRegistrosCYR.push({ ycl0:(this.laRegistrosCYR.length+1), y001:this.ltTipoCYR, y002:0, y003:"...", y011:"010100000000", y012: "010199999999", y013:"A", y014:"", y015:"#000000", y016:0, y017:1 });
    },

    //----------------------------------------------------------------------
    //-- Actualizar Registro UXA (en el servidor)
    //----------------------------------------------------------------------
    lp60ActualizarRUXA() {
      this.p99Log(5,1,'lp60ActualizarRUXA');

      //-- Datos del Registro
      this.rUXA.u001 = this.rUXA.u101 + this.rUXA.u102 + this.rUXA.u103 + this.rUXA.u104 + this.rUXA.u105 + this.rUXA.u106;
      this.rUXA.u002 = this.rUXA.u201 + this.rUXA.u202 + this.rUXA.u203 + this.rUXA.u204 + this.rUXA.u205 + this.rUXA.u206;

      var datosUXA = this.rUXA.u001+'^'+this.rUXA.u002+'^'+this.rUXA.u003+'^'+this.rUXA.u004+'^'+this.rUXA.u005+'^'+this.rUXA.u006+'^'+this.rUXA.u007+'^'+
                     this.rUXA.u021+'^'+this.rUXA.u022+'^'+this.rUXA.u023+'^'+this.rUXA.u031+'^'+this.rUXA.u041+'^'+this.rUXA.u042+'^'+
                     this.rUXA.u043+'^'+this.rUXA.u044+'^'+this.rUXA.u045+'^'+this.rUXA.u046+'^^^^';

      let i = 0;
      while (i<20) {
        //-- Concatenar Indice (i) de Agrupaciones a INCLUIR / OMITIR
        datosUXA += this.rUXA.u011[i] + '^' + this.rUXA.u012[i] + '^' + this.rUXA.u013[i] + '^' + this.rUXA.u014[i] + '^';
        //-- Siguiente
        i += 1;
      }

      //-- Enviar Solicitud Grabación al Servidor
      var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=921&x=" + encodeURIComponent(datosUXA);
      this.p99Log(5,1,' (lp60ActualizarRUXA) URL = '+URL);

      //-- Contactar con el Servidor del Distribuidor
      axios.get(URL, {responseEncoding: 'utf-8'})                         
      .then(respuesta => {

          //-- Extraer la respuesta recibida
          var datos = respuesta.data;

          //-- Determinar si ha habido EXITO ó ERROR
          var flg = global.lnkGetDataError;
          if (datos.flg) {  flg = datos.flg; }

          //-- Actualizar Resultados
          this.p99Log(1,1,'-- Respuesta (200921) --');
          this.p99Log(1,2,datos);

          if (flg == global.lnkGetDataOk)  {
            this.p99Log(1,2,' ¡¡ EXITO !!');

            //-- Determinar el MENSAJE a mostrar
            this.p99AnuncioIA1('1','7','¡ Éxito !', 'Ubicación x Agrupación de Artículo grabada correctamente.',3);

            //-- Ocultar la Ventana de Edición
            this.$modal.hide('VMLeyenda2');
          } else {
            if (datos.msg) { this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1); } else { this.p99AnuncioIA1('2','7','ERROR', respuesta.data,-1); }
          }

          //-- Refrescar Lista UXA
          this.lp98LeerListaUXA();

      })
      .catch(error => {
          //-- Mostrar el Mensaje de Error oportuno
          this.p99Log(1,1,'** ERROR **');
          this.p99Log(1,1,error);

          //-- Mensaje al Operador
          this.p99AnuncioIA1('2','7','ERROR', error,-1);
      });


    },

    //--------------------------------------------------------------------------------
    //-- Borrar los Registros MET marcados con la CheckBox
    //--------------------------------------------------------------------------------
    lp60BorrarRegistrosMETSeleccionados() {

      //-- Iniciar Bucle
      let i = 0;
      while (i < this.laRegistrosMET.length) {

        //-- Actualizar Indice Actual
        if (this.laRegistrosMET[i].mdma[0].dsw1) { this.lp60BorrarMET(i); }

        //-- Siguiente
        i += 1;
      }

      //-- Refrescar Resultados
      this.lbMarcarDesmarcarMET = false;
      this.lp98LeerListaMET();

    },

    //--------------------------------------------------------------------------------
    //-- Borrar Registro DMA (a partir del contenido MET)
    //--------------------------------------------------------------------------------
    lp60BorrarMET(index) {

      //-- Solicitar DETALLE MOVIMIENTO ALMACEN (provisional) asociado a un determinado HUECO
      var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=234&m=903&x=" + encodeURIComponent(this.laRegistrosMET[index].mdma[0].dkey);
      this.p99Log(5,1,' (lp60BorrarMET) URL = '+URL);

      //-- Contactar con el Servidor del Distribuidor
      axios.get(URL, {responseEncoding: 'utf-8'})                         
      .then(respuesta => {

          //-- Extraer la respuesta recibida
          var datos = respuesta.data;

          //-- Determinar si ha habido EXITO ó ERROR
          var flg = global.lnkGetDataError;
          if (datos.flg) {  flg = datos.flg; }

          //-- Actualizar Resultados
          this.p99Log(1,1,'-- Respuesta (234903) --');
          this.p99Log(1,2,datos);

          if (flg == global.lnkGetDataOk)  {
              this.p99Log(1,2,' ¡¡ EXITO !!');
          } else {                  
            this.p99Log(1,1,' #### ERROR (234903) ####');
            if (datos.msg) { this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1); } else { this.p99AnuncioIA1('2','7','ERROR', respuesta.data,-1); }
          }

      })
      .catch(error => {
          //-- Mostrar el Mensaje de Error oportuno
          this.p99Log(1,1,'** ERROR **');
          this.p99Log(1,1,error);

          //-- Mensaje al Operador
          this.p99AnuncioIA1('2','7','ERROR', "Error al tratar de BORRAR el Movimiento seleccionado",-1);
      });

    },


    //----------------------------------------------------------------------
    //-- Borrar Registro CYR (en el servidor)
    //----------------------------------------------------------------------
    lp60BorrarCYR() {
      this.p99Log(5,1,'lp60BorrarRCYR');

      //-- Datos del Registro
      var datosCYR = this.rCYR.y001+'^'+this.rCYR.y002;

      //-- Enviar Solicitud de Borrado al Servidor
      var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=933&x=" + encodeURIComponent(datosCYR);
      //this.p99Log(5,1,' (lp60BorrarRUXA) URL = '+URL);

      //-- Contactar con el Servidor del Distribuidor
      axios.get(URL, {responseEncoding: 'utf-8'})                         
      .then(respuesta => {

          //-- Extraer la respuesta recibida
          var datos = respuesta.data;

          //-- Determinar si ha habido EXITO ó ERROR
          var flg = global.lnkGetDataError;
          if (datos.flg) {  flg = datos.flg; }

          //-- Actualizar Resultados
          this.p99Log(1,1,'-- Respuesta (200933) --');
          this.p99Log(1,2,datos);

          if (flg == global.lnkGetDataOk)  {
            this.p99Log(1,2,' ¡¡ EXITO !!');

            //-- Determinar el MENSAJE a mostrar
            this.p99AnuncioIA1('1','7','¡ Éxito !', 'Camino y Recorrido borrado correctamente.',3);

            //-- Ocultar la Ventana de Edición
            this.$modal.hide('VMLeyenda3');
          } else {
            if (datos.msg) { this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1); } else { this.p99AnuncioIA1('2','7','ERROR', respuesta.data,-1); }
          }

          //-- Refrescar Lista UXA
          this.lp98LeerListaCYR();

      })
      .catch(error => {
          //-- Mostrar el Mensaje de Error oportuno
          this.p99Log(1,1,'** ERROR **');
          this.p99Log(1,1,error);

          //-- Mensaje al Operador
          this.p99AnuncioIA1('2','7','ERROR', error,-1);
      });


    },



    //----------------------------------------------------------------------
    //-- Borrar Registro UXA (en el servidor)
    //----------------------------------------------------------------------
    lp60BorrarRUXA() {
      this.p99Log(5,1,'lp60BorrarRUXA');

      //-- Datos del Registro
      this.rUXA.u001 = this.rUXA.u101 + this.rUXA.u102 + this.rUXA.u103 + this.rUXA.u104 + this.rUXA.u105 + this.rUXA.u106;
      this.rUXA.u002 = this.rUXA.u201 + this.rUXA.u202 + this.rUXA.u203 + this.rUXA.u204 + this.rUXA.u205 + this.rUXA.u206;

      var datosUXA = this.rUXA.u001+'^'+this.rUXA.u002+'^'+this.rUXA.u003;

      //-- Enviar Solicitud de Borrado al Servidor
      var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=923&x=" + encodeURIComponent(datosUXA);
      //this.p99Log(5,1,' (lp60BorrarRUXA) URL = '+URL);

      //-- Contactar con el Servidor del Distribuidor
      axios.get(URL, {responseEncoding: 'utf-8'})                         
      .then(respuesta => {

          //-- Extraer la respuesta recibida
          var datos = respuesta.data;

          //-- Determinar si ha habido EXITO ó ERROR
          var flg = global.lnkGetDataError;
          if (datos.flg) {  flg = datos.flg; }

          //-- Actualizar Resultados
          this.p99Log(1,1,'-- Respuesta (200923) --');
          this.p99Log(1,2,datos);

          if (flg == global.lnkGetDataOk)  {
            this.p99Log(1,2,' ¡¡ EXITO !!');

            //-- Determinar el MENSAJE a mostrar
            this.p99AnuncioIA1('1','7','¡ Éxito !', 'Ubicación x Agrupación de Artículo borrada correctamente.',3);

            //-- Ocultar la Ventana de Edición
            this.$modal.hide('VMLeyenda2');
          } else {
            if (datos.msg) { this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1); } else { this.p99AnuncioIA1('2','7','ERROR', respuesta.data,-1); }
          }

          //-- Refrescar Lista UXA
          this.lp98LeerListaUXA();

      })
      .catch(error => {
          //-- Mostrar el Mensaje de Error oportuno
          this.p99Log(1,1,'** ERROR **');
          this.p99Log(1,1,error);

          //-- Mensaje al Operador
          this.p99AnuncioIA1('2','7','ERROR', error,-1);
      });


    },

    //----------------------------------------------------------------------
    //-- Al MODIFICAR la UBICACIÓN de un Hueco
    //----------------------------------------------------------------------
    lp60CambioPosicionHueco(nuevoHueco, index) {
        this.laHuecosMapa[index].top     = nuevoHueco.top;
        this.laHuecosMapa[index].left    = nuevoHueco.left;
        this.laHuecosMapa[index].width   = nuevoHueco.width;
        this.laHuecosMapa[index].height  = nuevoHueco.height;
    },

    //----------------------------------------------------------------------
    //-- Al MODIFICAR el TAMAÑO de un Hueco
    //----------------------------------------------------------------------
    lp60CambioTamanoHueco(nuevoHueco, index) {
        this.laHuecosMapa[index].top     = nuevoHueco.top;
        this.laHuecosMapa[index].left    = nuevoHueco.left;
        this.laHuecosMapa[index].width   = nuevoHueco.width;
        this.laHuecosMapa[index].height  = nuevoHueco.height;
    },

    //----------------------------------------------------------------------
    //-- ALTA de nuevo hueco en el Mapa del Almacén
    //----------------------------------------------------------------------
    lp60CrearNuevoHueco() {
      this.p99Log(5,1,'Nuevo Hueco');
      
      this.lnContadorHueco += 1;

      this.laHuecosMapa.push({      //-- Agregar Nuevo Elemento a la Lista
                    'id':           '_'+this.lnContadorHueco,
                    'left':         100,
                    'top':          100,
                    'width':        50,
                    'height':       50,
                    'draggable':    true,
                    'resizable':    true,
                    'minw':         10,
                    'minh':         10,
                    'axis':         'both',
                    'parentLim':    true,
                    'snapToGrid':   false,
                    'aspectRatio':  false,
                    'zIndex':       1,
                    'color':        global.lnkDefaultMPAColors[10],
                    'active':       false,
                    'preventActive':!this.lSWMostrarHerramientasDibujo,
                    'class':        'box-nuevo',
                    'articulo':     '',
                    'descripcionArticulo': '',
                    'tipoHueco':    '0',
                    'nroPales':     '1',
                    'porcentaje' : 0
                  });

    },

    //----------------------------------------------------------------------
    //-- Creación de Nuevo Registro UXA
    //----------------------------------------------------------------------
    lp60CrearNuevoUXA() {
      //-- Cargar Último Registro (Propiedades VXD)
      this.rUXA = this.laRegistrosUXA[this.laRegistrosUXA.length-1];

      //-- Mostrar Ventana de Edición Registro UXA
      this.$modal.show('VMLeyenda2');
    },   


    //----------------------------------------------------------------------
    //-- Edición de Registro CYR existente
    //----------------------------------------------------------------------
    lp60EditarCYR(indice) {
      //-- Cargar Registro
      this.rCYR = this.laRegistrosCYR[indice];

      //-- Mostrar Ventana de Edición Registro CYR
      this.$modal.show('VMLeyenda3');
    },

    //----------------------------------------------------------------------
    //-- Edición de Registro UXA existente
    //----------------------------------------------------------------------
    lp60EditarUXA(indice) {
      //-- Cargar Registro
      this.rUXA = this.laRegistrosUXA[indice];

      //-- Mostrar Ventana de Edición Registro UXA
      this.$modal.show('VMLeyenda2');
    } ,

    //----------------------------------------------------------------------
    //-- Ordenar Lista de Registros CYR
    //----------------------------------------------------------------------
    lp60OrdenarCYR(indice) {
        //-- Ordenar la Lista de Registros
        let nuevaListaCYR = this.laRegistrosCYR.sort(function(a, b){
                                              //-- Número de Orden
                                              if (indice == 2) {
                                                if (a.y002 < b.y002) { return -1; }
                                                if (a.y002 > b.y002) { return  1; }
                                              }

                                              //-- Posición
                                              if (indice == 13) {
                                                if (a.y013 < b.y013) { return -1; }
                                                if (a.y013 > b.y013) { return  1; }
                                              }

                                              //-- Zona de Carga
                                              if (indice == 14) {
                                                if (a.y014 < b.y014) { return -1; }
                                                if (a.y014 > b.y014) { return  1; }
                                              }

                                              //-- Color (RGB)
                                              if (indice == 15) {
                                                if (a.y015 < b.y015) { return -1; }
                                                if (a.y015 > b.y015) { return  1; }
                                              }

                                              return 0; 
                                          });

        //-- Actualizar
        this.laRegistrosCYR = nuevaListaCYR;                                          
    },

    //----------------------------------------------------------------------
    //-- Al conmutar de valor la check-box (Dibujar)
    //----------------------------------------------------------------------
    lp60SwitchHerramientasDibujo(evento) {
      console.dir(evento);
      //this.p99Log(5,1,'lp60SwitchHerramientasDibujo - '+(this.lSWMostrarHerramientasDibujo?'Sí':'No'));

      for (var r of this.laHuecosMapa) {
        r.preventActive = (!this.lSWMostrarHerramientasDibujo);
      }
    },

    //----------------------------------------------------------------------
    //-- Al seleccionar un NUEVO VALOR en la Lista de Artículos
    //----------------------------------------------------------------------
    lp60NuevoArticuloBuscado() {
      //-- Extraer Valores del Objeto 
      this.ltArticuloBusquedaCodigo = this.loArticuloBusqueda.a001;
      console.log(' Artículo ('+this.ltArticuloBusquedaCodigo+')');

      //-- Ejecutar la Búsqueda
      setTimeout(() => {
        this.lp60BusquedaArticulosBAM();
      },100);

    },

    //----------------------------------------------------------------------
    //-- Búsqueda de ARTÍCULOS en el Mapa
    //----------------------------------------------------------------------
    lp60BusquedaArticulosBAM() {
      this.p99Log(5,1,' -- lp60BusquedaArticulosBAM --');

      //-- Inicializar Rótulos/Textos asociados a la búsqueda
      this.lbCambioArticuloBusquedaEnCurso = false;
      this.ltHuecoResaltado              = '?';
      this.ltArticuloBusquedaDescripcion = ' Buscando ... ';
      this.ltArticuloBusquedaFormato     = '';
      this.laRegistrosBAM                = [];

      //-- Enviar Solicitud de Borrado al Servidor
      var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=011&x=" + encodeURIComponent(this.ltArticuloBusquedaCodigo);
      //this.p99Log(5,1,' (lp60BorrarRUXA) URL = '+URL);

      //-- Contactar con el Servidor del Distribuidor
      axios.get(URL, {responseEncoding: 'utf-8'})                         
      .then(respuesta => {

          //-- Extraer la respuesta recibida
          var datos = respuesta.data;

          //-- Determinar si ha habido EXITO ó ERROR
          var flg = global.lnkGetDataError;
          if (datos.flg) {  flg = datos.flg; }

          //-- Actualizar Resultados
          this.p99Log(1,1,'-- Respuesta (200011) --');
          this.p99Log(1,2,datos);

          if (flg == global.lnkGetDataOk)  {
            this.p99Log(1,2,' ¡¡ EXITO !!');

            //-- Actualizar Relación de Registros
            this.laRegistrosBAM = datos.t200m011.sort(function(a, b){
                                                  if (a.m001 < b.m001) { return -1; }
                                                  if (a.m001 > b.m001) { return  1; }
                                                  return 0; 
                                              });

            //-- Descripción Artículo
            if (datos.t200m011.length == 0) {
              this.ltArticuloBusquedaDescripcion = '-- Sin Resultados --';
              this.ltArticuloBusquedaFormato     = '';
            } else {
              this.ltArticuloBusquedaDescripcion = datos.t200m011[0].a002;
              this.ltArticuloBusquedaFormato     = datos.t200m011[0].a917;
            }

            //-- Refrescar el contenido del Mapa a mostrar
            this.lp90RefrescarMapa(0);

          } else {
            if (datos.msg) { this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1); } else { this.p99AnuncioIA1('2','7','ERROR', respuesta.data,-1); }
          }

      })
      .catch(error => {
          //-- Mostrar el Mensaje de Error oportuno
          this.p99Log(1,1,'** ERROR **');
          this.p99Log(1,1,error);

          //-- Mensaje al Operador
          this.p99AnuncioIA1('2','7','ERROR', error,-1);
      });


    },

    //----------------------------------------------------------------------
    //-- Búsqueda de MOVIMIENTOS EN TRÁNSITO
    //----------------------------------------------------------------------
    lp98LeerListaMET() {
      this.p99Log(5,1,' -- lp98LeerListaMET --');

      //-- Inicializar Resultados
      this.ltHuecoResaltado              = '?';
      this.laRegistrosMET_Completa       = [];

      //-- Enviar Solicitud de Borrado al Servidor
      var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=091";

      //-- Contactar con el Servidor del Distribuidor
      axios.get(URL, {responseEncoding: 'utf-8'})                         
      .then(respuesta => {

          //-- Extraer la respuesta recibida
          var datos = respuesta.data;

          //-- Determinar si ha habido EXITO ó ERROR
          var flg = global.lnkGetDataError;
          if (datos.flg) {  flg = datos.flg; }

          //-- Actualizar Resultados
          this.p99Log(1,1,'-- Respuesta (200091) --');
          this.p99Log(1,2,datos);

          if (flg == global.lnkGetDataOk)  {
            this.p99Log(1,2,' ¡¡ EXITO !!');

            //-- Cargar la LISTA COMPLETA
            this.laRegistrosMET_Completa = datos.t200m091;

            //-- Filtrar y Ordenar la Lista Filtrada
            this.lp60FiltrarRegistrosMET();

            //-- Refrescar el contenido del Mapa a mostrar
            this.lp90RefrescarMapa(0);

          } else {
            if (datos.msg) { this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1); } else { this.p99AnuncioIA1('2','7','ERROR', respuesta.data,-1); }
          }

      })
      .catch(error => {
          //-- Mostrar el Mensaje de Error oportuno
          this.p99Log(1,1,'** ERROR **');
          this.p99Log(1,1,error);

          //-- Mensaje al Operador
          this.p99AnuncioIA1('2','7','ERROR', error,-1);
      });


    },


    //---------------------------------------------------------------------------------
    //-- Al ABRIR la Lista de Artículos
    //---------------------------------------------------------------------------------
    lf60ListaAbierta() {
    },


    //---------------------------------------------------------------------------------
    //-- Al CERRAR la Lista de Artículos
    //---------------------------------------------------------------------------------
    lf60ListaCerrada() {
    },


    //----------------------------------------------------------------------
    //-- Resaltar el Hueco solicitado
    //----------------------------------------------------------------------
    lp60ResaltarBAM(indice) {
      this.p99Log(5,1,' lp60ResaltarBAM ('+indice+')');

      //-- Identificar Hueco a Resaltar
      this.ltHuecoResaltado = this.laRegistrosBAM[indice].m001;

      //-- Actualizar ALTURA (si procede)
      if (!this.lbModoAlzada) {
        let altura = this.ltHuecoResaltado.substr(8,2);
        if (altura != this.ltMapaAltura) { this.ltMapaAltura = altura; }
      }

      //-- Refrescar el contenido del Mapa a mostrar
      this.lp90RefrescarMapa(0);
    },


    //----------------------------------------------------------------------
    //-- Marcar/Desmarcar TODOS los registros MET mostrados en pantalla
    //----------------------------------------------------------------------
    lp60MarcarDesmarcarMET() {

      //-- Iniciar Bucle
      let i = 0;
      while (i < this.laRegistrosMET.length) {

        //-- Actualizar Indice Actual
        this.laRegistrosMET[i].mdma[0].dsw1 = !this.lbMarcarDesmarcarMET;

        //-- Siguiente
        i += 1;
      }

    },

    //----------------------------------------------------------------------
    //-- Resaltar el Hueco solicitado
    //----------------------------------------------------------------------
    lp60ResaltarMET(indice) {
      this.p99Log(5,1,' lp60ResaltarMET ('+indice+')');

      //-- Identificar Hueco a Resaltar
      this.ltHuecoResaltado = this.laRegistrosMET[indice].m001;

      //-- Refrescar el contenido del Mapa a mostrar
      this.lp90RefrescarMapa(0);
    },


    //----------------------------------------------------------------------
    //-- Determinar el Índice UXA equivalente a la Ubicación suministrada
    //----------------------------------------------------------------------
    lf65IndiceUXA(ubicacion) {
      //-- Valor Predeterminado : (NO Encontrado)
      let indice = -1;

      //-- Búsqueda de un Índice Válido
      let i = 0;

      while ((indice == -1) && (i < this.laRegistrosUXA.length)) {
        //-- Evaluar
        if ((ubicacion >= this.laRegistrosUXA[i].u001) && (ubicacion <= this.laRegistrosUXA[i].u002)) {
           //-- ¿ Ubicación Mostrable ?
           if (this.laUXASeleccionados.indexOf(this.laRegistrosUXA[i].ucl0) >= 0) {
            indice = i;     //-- Mostrar
           } else {
            indice = -2;    //-- Ocultar (NO RESALTAR)
           }
        } 

        //-- Siguiente
        i += 1;
      }

      //-- Retornar el Resultado obtenido
      return indice;
    },


    //----------------------------------------------------------------------
    //-- Determinar el Índice BAM equivalente a la Ubicación suministrada
    //----------------------------------------------------------------------
    lf65IndiceBAM(ubicacion) {
      //-- Valor Predeterminado : (NO Encontrado)
      let indice = -1;

      //-- Búsqueda de un Índice Válido
      let i = 0;

      while ((indice == -1) && (i < this.laRegistrosBAM.length)) {

        //-- Evaluar
        if (ubicacion == this.laRegistrosBAM[i].m001) {
            indice = i;     //-- Encontrado
        } 

        //-- Siguiente
        i += 1;
      }

      //-- Retornar el Resultado obtenido
      return indice;
    },

    //----------------------------------------------------------------------
    //-- Determinar el Índice MET equivalente a la Ubicación suministrada
    //----------------------------------------------------------------------
    lf65IndiceMET(ubicacion) {
      //-- Valor Predeterminado : (NO Encontrado)
      let indice = -1;

      //-- Búsqueda de un Índice Válido
      let i = 0;

      while ((indice == -1) && (i < this.laRegistrosMET.length)) {

        //-- Evaluar
        if (ubicacion == this.laRegistrosMET[i].m001) {
            indice = i;     //-- Encontrado
        } 

        //-- Siguiente
        i += 1;
      }

      //-- Retornar el Resultado obtenido
      return indice;
    },

    //----------------------------------------------------------------------
    //-- Determinar si se trata de un NUEVA CALLE
    //----------------------------------------------------------------------

    //..............
    //:: Planta
    //..............
    lf88NuevaCallePlanta(calle) {

      let resultado = false;

      if ((calle.substr(0,4) == '0111') && (calle.substr(6,2) == '01')) {
        resultado = true;
        this.p99Log(5,1,calle + ' SÍ');
      } else {
        this.p99Log(5,1,calle + ' NO');
      }

      return resultado;

    },

    //..............
    //:: Alzada
    //..............
    lf88NuevaCalleAlzada(calle) {

      let resultado = false;      
      if (calle.substr(6,4) == '0110') {
        resultado = true;
        this.p99Log(5,1,calle + ' SÍ');
      } else {
        this.p99Log(5,1,calle + ' NO');
      }

      return resultado;

    },


    //----------------------------------------------------------------------
    //-- Determinar si se trata de un NUEVO PASILLO
    //----------------------------------------------------------------------
    lf88NuevoPasillo(pasillo) {

      let resultado = false;

      //console.log('Distribuidor = '+this.$store.state.strDistribuidor);

      if (this.$store.state.strDistribuidor == 'D070001') {
        return false;
      }

      //-- Norma General
      if (pasillo.substr(4,4) == '0101') {
        resultado = true;
      }

      //-- DISCEAS
      if (this.$store.state.strDistribuidor == 'D050001') {
        if (pasillo.substr(0,4) == '0151') {
          resultado = false;
          if (pasillo.substr(2,6) == '511401') { resultado = true; }
        }

        if ((pasillo.substr(0,4) >= '0161') && (pasillo.substr(0,4) <= '0173')) {
          resultado = false;
        }
      }

      //-- Log
      if (resultado == true) {
        this.p99Log(5,1,pasillo + ' SÍ');
      } else {
        this.p99Log(5,1,pasillo + ' NO');
      }

      //-- Retornar Resultado
      return resultado;

    },

    //----------------------------------------------------------------------
    //-- Cambiar el Tipo de Vista
    //----------------------------------------------------------------------
    lp88CambiarTipoVista() {
      //-- Conmutar
      this.lbModoAlzada = !this.lbModoAlzada;

      //-- Eliminar Refresco Automático
      this.lp90EliminarTimer();

      //-- Inicializar Periodicidad (Segundos)
      this.lnSegundosTimer = 0;

      //-- Refrescar el contenido del Mapa a mostrar
      this.lp90RefrescarMapa(0);
    },


    //----------------------------------------------------------------------
    //-- Cambiar la Frecuencia de Refresco
    //----------------------------------------------------------------------    
    lp90CambioTimer() {

      //-- Eliminar Refresco Automático
      this.lp90EliminarTimer();

      //-- Programar Nuevo Refresco Automático
      if (this.lnSegundosTimer > 0) {
        this.p99Log(5,1,'.. Creando nuevo Timer cada '+this.lnSegundosTimer+' segundos ..');
        this.lrRefreshTimer = setInterval(function(){
            this.p99Log(5,1,'-- Inicio Lectura Mapa --');
            this.lp90RefrescarDatosYMapa();
            this.p99Log(5,1,'-- Fin Lectura Mapa --');
          }.bind(this), this.lnSegundosTimer * 1000);
      }

    },   

    //----------------------------------------------------------------------
    //-- Eliminar el Refresco Automático
    //----------------------------------------------------------------------    
    lp90EliminarTimer() {
      this.p99Log(5,1,'** Eliminando ANTIGUO Timer **');

      //-- Eliminar Timer
      clearInterval(this.lrRefreshTimer);

    },

    //----------------------------------------------------------------------
    //-- Mostrar (Detalles de un Hueco)
    //----------------------------------------------------------------------
    lp90Mostrar11190() {
      this.p99Log(5,1,'-- Mostrando Ventana Auxiliar --');
      this.lSW11190_Visible = true;
    },

    //----------------------------------------------------------------------
    //-- Ocultar (Detalles de un Hueco)
    //----------------------------------------------------------------------
    lp90Ocultar11190() {
      this.lSW11190_Visible = false;
    },    

    //----------------------------------------------------------------------
    //-- Refrescar la Visualización del Mapa
    //----------------------------------------------------------------------
    lp90RefrescarDatosYMapa() {
      
      if        (this.lnTipoEdicion == 0) {   //-- Lectura Huecos del Mapa a representar
        this.lp98LeerMapa(''); 
      } else if (this.lnTipoEdicion == 91) {  //-- Monitor Movimientos en Tránsito
        this.lp98LeerListaMET();              //.. Reclamar DATOS (tras recibir RESPUESTA ... ya se repinta el Mapa [no hay que refrescar Mapa])
      } else {
        this.lp90RefrescarMapa(0);            //-- Repintar los Huecos del Mapa
      }

    },
    

    //----------------------------------------------------------------------
    //-- Refrescar la Visualización del Mapa
    //----------------------------------------------------------------------
    lp90RefrescarMapa(pausa) {
      this.laHuecosMapa = [];

      //-- Construir Mapa
      setTimeout(() => {

        var nuevoMapa = [];
        let color     = '';
        let indice    = -1;
        let claseCSS  = '';

        for (const item of this.laRegistrosMPA) {

            //..........................................
            //-- Inicializar la Apariencia del Hueco
            //..........................................
            claseCSS = 'box-hueco';
            if (item.m011 == '1') { claseCSS = 'box-silo-mono'; }
            if (item.m011 == '6') { claseCSS = 'box-silo-multi'; }

            //::::::::::::::::::::::::::::::::::::::::::::::::::::::
            //-- Consulta GENERAL del Mapa
            //::::::::::::::::::::::::::::::::::::::::::::::::::::::
            if (this.lnTipoEdicion == 0) {

              //-- Color (en función del Índice de Ocupación del Hueco)
              if (this.lnFiltroMapaActividad == 2) {
                color = global.lnkDefaultMPAColors[1]; 
              } else {
                color = this.f80ColorGradienteStock(item.m991, this.lnFiltroMapaPorcentaje);
              }

              //-- Resaltar huecos CON MOVIMIENTOS PENDIENTES EN CURSO
              if ((item.m992 != '00') && (this.lnFiltroMapaActividad != 1)) { 

                switch (item.m992) {
                  case '10':                                   //-- Compras
                    if ((item.m993 == '4') || (item.m993 == '5')) { 
                      color = global.lnkDefaultMPAColors[13]; 
                    } else {
                      color = global.lnkDefaultMPAColors[12]; 
                    }
                    break;

                  case '20':                                   //-- Cargas
                   if ((item.m993 == '4') || (item.m993 == '5')) { 
                      color = global.lnkDefaultMPAColors[15]; 
                    } else {
                      color = global.lnkDefaultMPAColors[14]; 
                    }
                    break;

                  case '21':                                   //-- Retornos
                   if ((item.m993 == '4') || (item.m993 == '5')) { 
                      color = global.lnkDefaultMPAColors[17]; 
                    } else {
                      color = global.lnkDefaultMPAColors[16]; 
                    }
                    break;

                  case '2C':                                   //-- Venta Directa
                   if ((item.m993 == '4') || (item.m993 == '5')) { 
                      color = global.lnkDefaultMPAColors[15]; 
                    } else {
                      color = global.lnkDefaultMPAColors[14]; 
                    }
                    break;

                  case '2P':                                   //-- Peso Variable
                   if ((item.m993 == '4') || (item.m993 == '5')) { 
                      color = global.lnkDefaultMPAColors[15]; 
                    } else {
                      color = global.lnkDefaultMPAColors[14]; 
                    }
                    break;

                  case '30':                                   //-- Movimientos entre Almacenes
                    if ((item.m993 == '4') || (item.m993 == '5')) { 
                      color = global.lnkDefaultMPAColors[19]; 
                    } else {
                      color = global.lnkDefaultMPAColors[18]; 
                    }
                    break;

                  case '40':                                   //-- Recuentos
                    if ((item.m993 == '4') || (item.m993 == '5')) { 
                      color = global.lnkDefaultMPAColors[21]; 
                    } else {
                      color = global.lnkDefaultMPAColors[20]; 
                    }
                    break;

                  default :                                   //-- Múltiples Tipos de Movimiento
                    if ((item.m993 == '4') || (item.m993 == '5')) { 
                      color = global.lnkDefaultMPAColors[23]; 
                    } else {
                      color = global.lnkDefaultMPAColors[22]; 
                    }
                    break;
                }

                
              }   
            }

            //::::::::::::::::::::::::::::::::::::::::::::::::::::::
            //-- Consulta UBICACIONES x AGRUPACIÓN DE ARTÍCULO
            //::::::::::::::::::::::::::::::::::::::::::::::::::::::
            if (this.lnTipoEdicion == 1) {
              indice = this.lf65IndiceUXA(item.m001);

              if        (indice == -2) {
                color = global.lnkDefaultMPAColors[1];        //-- Agrupacion NO MOSTRABLE (a EXCLUIR en la Representación del Mapa)
              } else if (indice == -1) {
                color = global.lnkDefaultMPAColors[11];       //-- Agrupacion DESCONOCIDA
              } else {
                color = this.laRegistrosUXA[indice].u041;     //-- Agrupación CONOCIDA y MOSTRABLE
              }
            }

            //::::::::::::::::::::::::::::::::::::::::::::::::::::::
            //-- Consulta CAMINOS Y RECORRIDOS
            //::::::::::::::::::::::::::::::::::::::::::::::::::::::
            if (this.lnTipoEdicion == 2) {
              color = global.lnkDefaultMPAColors[9];          //-- SÍ
            }

            //::::::::::::::::::::::::::::::::::::::::::::::::::::::
            //-- Búsqueda de Artículos
            //::::::::::::::::::::::::::::::::::::::::::::::::::::::
            if (this.lnTipoEdicion == 11) {

              //-- ¿ Somos el HUECO seleccionado (a resaltar) ?
              if (item.m001 == this.ltHuecoResaltado) {

                 color = global.lnkDefaultMPAColors[7]; 
                 claseCSS = 'box-resaltado';

              } else {

                //-- Buscar entre los resultados BAM
                indice = this.lf65IndiceBAM(item.m001);

                if (indice < 0) {
                  color = global.lnkDefaultMPAColors[8];    //-- No hay presencia del artículo en este Hueco
                } else {
                  color = global.lnkDefaultMPAColors[9];    //-- Este Hueco contiene porciones del Artículo Buscado
                  claseCSS = 'box-encontrado';
                }               

              }

            }

            //::::::::::::::::::::::::::::::::::::::::::::::::::::::
            //-- Artículos Bajo Mínimos
            //::::::::::::::::::::::::::::::::::::::::::::::::::::::
            if (this.lnTipoEdicion == 12) {
              //indice = this.lf65IndiceUXA(item.m001);
               color = global.lnkDefaultMPAColors[8];        //-- Ni SÍ ni NO
            }

            //::::::::::::::::::::::::::::::::::::::::::::::::::::::
            //-- Muertos de Almacén
            //::::::::::::::::::::::::::::::::::::::::::::::::::::::
            if (this.lnTipoEdicion == 13) {
              //indice = this.lf65IndiceUXA(item.m001);
               color = global.lnkDefaultMPAColors[8];        //-- Ni SÍ ni NO
            }

            //::::::::::::::::::::::::::::::::::::::::::::::::::::::
            //-- Reaprovisionamiento
            //::::::::::::::::::::::::::::::::::::::::::::::::::::::
            if (this.lnTipoEdicion == 14) {
              //indice = this.lf65IndiceUXA(item.m001);
               color = global.lnkDefaultMPAColors[8];        //-- Ni SÍ ni NO
            }

            //::::::::::::::::::::::::::::::::::::::::::::::::::::::
            //-- Monitor Movimientos Provisionales (En Tránsito)
            //::::::::::::::::::::::::::::::::::::::::::::::::::::::
            if (this.lnTipoEdicion == 91) {

              //-- ¿ Somos el HUECO seleccionado (a resaltar) ?
              if (item.m001 == this.ltHuecoResaltado) {

                color = global.lnkDefaultMPAColors[10]; 
                claseCSS = 'box-resaltado';

              } else {

                //-- Buscar entre los resultados MET
                indice = this.lf65IndiceMET(item.m001);

                if (indice < 0) {
                  color = global.lnkDefaultMPAColors[8];    //-- Hueco SIN Movimientos en TRÁNSITO
                } else {
                  //color = global.lnkDefaultMPAColors[12];   //-- Hueco EN TRÁNSITO (CON Movimientos Pendientes)

                  //-- Aplicar Color según el (Estado) del Hueco
                  switch (this.laRegistrosMET[indice].mdma[0].d011) {
                    case '10':                                   //-- Compras
                      if ((this.laRegistrosMET[indice].mdma[0].d009 == '4') || (this.laRegistrosMET[indice].mdma[0].d009 == '5')) { 
                        color = global.lnkDefaultMPAColors[13]; 
                      } else {
                        color = global.lnkDefaultMPAColors[12]; 
                      }
                      break;

                  case '20':                                   //-- Cargas
                      if ((this.laRegistrosMET[indice].mdma[0].d009 == '4') || (this.laRegistrosMET[indice].mdma[0].d009 == '5')) { 
                        color = global.lnkDefaultMPAColors[15]; 
                      } else {
                        color = global.lnkDefaultMPAColors[14]; 
                      }
                      break;

                  case '21':                                   //-- Retornos
                      if ((this.laRegistrosMET[indice].mdma[0].d009 == '4') || (this.laRegistrosMET[indice].mdma[0].d009 == '5')) { 
                        color = global.lnkDefaultMPAColors[17]; 
                      } else {
                        color = global.lnkDefaultMPAColors[16]; 
                      }
                      break;

                  case '2C':                                 //-- Venta Directa
                      if ((this.laRegistrosMET[indice].mdma[0].d009 == '4') || (this.laRegistrosMET[indice].mdma[0].d009 == '5')) { 
                        color = global.lnkDefaultMPAColors[15]; 
                      } else {
                        color = global.lnkDefaultMPAColors[14]; 
                      }
                      break;

                  case '2P':                                 //-- Peso Variable
                      if ((this.laRegistrosMET[indice].mdma[0].d009 == '4') || (this.laRegistrosMET[indice].mdma[0].d009 == '5')) { 
                        color = global.lnkDefaultMPAColors[15]; 
                      } else {
                        color = global.lnkDefaultMPAColors[14]; 
                      }
                      break;

                  case '30':                                   //-- Movimientos entre Almacenes
                      if ((this.laRegistrosMET[indice].mdma[0].d009 == '4') || (this.laRegistrosMET[indice].mdma[0].d009 == '5')) { 
                        color = global.lnkDefaultMPAColors[19]; 
                      } else {
                        color = global.lnkDefaultMPAColors[18]; 
                      }
                      break;

                  case '40':                                   //-- Recuentos
                      if ((this.laRegistrosMET[indice].mdma[0].d009 == '4') || (this.laRegistrosMET[indice].mdma[0].d009 == '5')) { 
                        color = global.lnkDefaultMPAColors[21]; 
                      } else {
                        color = global.lnkDefaultMPAColors[20]; 
                      }
                      break;

                  default :                                   //-- Múltiples Tipos de Movimiento
                      if ((this.laRegistrosMET[indice].mdma[0].d009 == '4') || (this.laRegistrosMET[indice].mdma[0].d009 == '5')) { 
                        color = global.lnkDefaultMPAColors[23]; 
                      } else {
                        color = global.lnkDefaultMPAColors[22]; 
                      }
                      break;
                  }

                  //-- Clase del Hueco
                  claseCSS = 'box-encontrado';
                }               

              }

            }

            //................................................
            //-- Determinar si se debe agregar NUEVO HUECO
            //................................................
            var lbIncorporar = true;
            if ((!this.lbModoAlzada) &&  (item.m001.substr(6,2) != '00') &&  (item.m001.substr(8,1) != this.ltMapaAltura.substr(0,1)))  { lbIncorporar = false; }
            if ( (this.lbModoAlzada) &&  (item.m001.substr(2,2) != this.ltMapaPasillo)) { lbIncorporar = false; }

            let iTop = item.m009 - this.lnMapaOffsetY;
            //if (item.m001.substr(9,1) == '5') { iTop += (item.m001.substr(9,1) * (3.5 * this.lnZoomY * this.lnMapaEscala)); }
            let iLeft = item.m008 * this.lnZoomX * this.lnMapaEscala;

            if (this.lbModoAlzada) {
              let offsetVertical = 0;
              if (item.m001.substr(9,1) == '5') { offsetVertical = (item.m001.substr(9,1) * (3.5 * this.lnZoomY * this.lnMapaEscala)); }

              iTop = window.innerHeight - 250 - (item.m001.substr(8,1) * ((item.m014 + 10) * this.lnZoomY * this.lnMapaEscala)) - offsetVertical;
              iLeft = 10 + (item.m001.substr(4,2) * (((item.m012 * 4) + 10) * this.lnZoomY * this.lnMapaEscala)) + (item.m001.substr(6,2) * ((item.m012 + 10) * this.lnZoomY * this.lnMapaEscala));
            }

            //-- Añadir al MAPA 
            if (lbIncorporar) {

              if (item.m001.substr(0,10) == '0111010110') {
                this.p99Log(5,1,'');
                this.p99Log(5,1,' (0111010110)  x='+item.m008+' y='+item.m009+' w='+item.m012+' h='+item.m014);
                this.p99Log(5,1,' {0111010110}  x='+item.m008 * this.lnZoomX * this.lnMapaEscala+' y='+iTop+' w='+item.m012 * this.lnZoomX * this.lnMapaEscala+' h='+item.m014 * this.lnZoomY * this.lnMapaEscala);
                this.p99Log(5,1,' iTop = '+window.innerHeight+'- 250 - ('+item.m001.substr(8,1)+'* ('+(item.m014 + 10)+'*'+this.lnZoomY+'*'+this.lnMapaEscala+')')
              }

              //-- Agregar Nuevo Elemento a la Lista
              nuevoMapa.push({            
                'id':           item.m001,
                'left':         iLeft,
                'top':          iTop, /* this.lnZoomY * this.lnMapaEscala, */
                'width':        item.m012 * this.lnZoomX * this.lnMapaEscala,
                'height':       item.m014 * this.lnZoomY * this.lnMapaEscala,
                'draggable':    true,
                'resizable':    true,
                'minw':         10,
                'minh':         10,
                'axis':         'both',
                'parentLim':    true,
                'snapToGrid':   false,
                'aspectRatio':  false,
                'zIndex':       1,
                'color':        color,
                'active':       false,
                'preventActive':!this.lSWMostrarHerramientasDibujo,
                'class':        claseCSS,
                'articulo':     item.m022,
                'descripcionArticulo': item.a002,
                'tipoHueco':    item.m011,
                'nroPales':     item.m033,
                'porcentaje':   item.m991
              });

            }

        }

        //-- Reemplazar Lista de HUECOS del Mapa Actual
        this.laHuecosMapa = nuevoMapa;

        //-- Mensaje de Éxito
        this.p99Log(5,1,' **** EXITO ****');
        //this.p99AnuncioIA1('1','7','¡ Éxito !', 'El MAPA ha sido cargado correctamente.',3);

      }, pausa);

    },

    //...........................................................
    //-- CSS a aplicar sobre la Etiqueta del Pasillo
    //...........................................................
    lf91ClaseEtiquetaPasillo(ancho, alto) {
      //-- Clase (Tipo de Fuente)
      let clase = 'igs-fs-10';
      if (alto <= 40)   { clase = 'igs-fs-8'; }
      if (alto <= 20)   { clase = 'igs-fs-7'; }

      //-- Posible Rotación
      if (ancho < alto) { clase += ' igs-rotacion-90'; }

      //-- Clase Completa
      return clase;
    },

    //----------------------------------------------------------------------
    //-- Color (en función del Estado del Registro)
    //----------------------------------------------------------------------
    lf91ColorEstado(estado) {
      if (estado == 'S') {
        return '#009900';
      } else {
        return '#ff0000';
      }
    },

    //----------------------------------------------------------------------
    //-- Color (en función del Tipo de Movimiento)
    //----------------------------------------------------------------------
    lf91ColorMET(index) {

      //-- Color Predeterminado
      let color = global.lnkDefaultMPAColors[22];

      //-- Determinar el color en función del Tipo de Movimiento
      if ((this.laRegistrosMET[index].mdma[0].d011 >= "10") && (this.laRegistrosMET[index].mdma[0].d011 <= "19")) { color = global.lnkDefaultMPAColors[12]; }

      if  (this.laRegistrosMET[index].mdma[0].d011 == "20")                                                       { color = global.lnkDefaultMPAColors[14]; }
      if  (this.laRegistrosMET[index].mdma[0].d011 == "21")                                                       { color = global.lnkDefaultMPAColors[16]; }
      if ((this.laRegistrosMET[index].mdma[0].d011 >= "22") && (this.laRegistrosMET[index].mdma[0].d011 <= "29")) { color = global.lnkDefaultMPAColors[14]; }

      if ((this.laRegistrosMET[index].mdma[0].d011 >= "30") && (this.laRegistrosMET[index].mdma[0].d011 <= "39")) { color = global.lnkDefaultMPAColors[18]; }
      if ((this.laRegistrosMET[index].mdma[0].d011 >= "40") && (this.laRegistrosMET[index].mdma[0].d011 <= "49")) { color = global.lnkDefaultMPAColors[20]; }

      //-- Retornar el color a aplicar
      return 'color: '+color;
    },

    //----------------------------------------------------------------------
    //-- Imagen (en función del Tipo de Movimiento)
    //----------------------------------------------------------------------
    lf91ImagenMET(index) {

      //-- Imagen Predeterminada
      let imagen = 'ia1-met-varios';

      //-- Determinar la apariencia de la IMAGEN en función del Tipo de Movimiento
      if ((this.laRegistrosMET[index].mdma[0].d011 >= "10") && (this.laRegistrosMET[index].mdma[0].d011 <= "19")) { imagen = 'ia1-met-entradas'; }

      if  (this.laRegistrosMET[index].mdma[0].d011 == "20")                                                       { imagen = 'ia1-met-cargas'; }
      if  (this.laRegistrosMET[index].mdma[0].d011 == "21")                                                       { imagen = 'ia1-met-retornos'; }
      if ((this.laRegistrosMET[index].mdma[0].d011 >= "22") && (this.laRegistrosMET[index].mdma[0].d011 <= "29")) { imagen = 'ia1-met-salidas'; }
      if  (this.laRegistrosMET[index].mdma[0].d011 == "2C")                                                       { imagen = 'ia1-met-ventas'; }

      if ((this.laRegistrosMET[index].mdma[0].d011 >= "30") && (this.laRegistrosMET[index].mdma[0].d011 <= "39")) { imagen = 'ia1-met-traspasos'; }
      if ((this.laRegistrosMET[index].mdma[0].d011 >= "40") && (this.laRegistrosMET[index].mdma[0].d011 <= "49")) { imagen = 'ia1-met-recuentos'; }

      if ((this.laRegistrosMET[index].mdma[0].d011 >= "60") && (this.laRegistrosMET[index].mdma[0].d011 <= "69")) { imagen = 'ia1-met-reubicaciones'; }

      //-- Determinar si se trata de ACTIVIDAD : Confirmada / No Confirmada
      let estado = 'off';
      if (this.laRegistrosMET[index].mdma[0].d009 >= '4') { estado = 'on'; }

      //.. Agregar estado
      imagen = imagen + '-' + estado;

      //-- Temporal
      /*
      if (index == 0) { imagen = "ia1-met-entradas-off";    }
      if (index == 1) { imagen = "ia1-met-entradas-on";     }
      if (index == 2) { imagen = "ia1-met-provisional-off"; }
      if (index == 3) { imagen = "ia1-met-provisional-on";  }
      
      if (index == 4) { imagen = "ia1-met-cargas-off";      }
      if (index == 5) { imagen = "ia1-met-cargas-on";       }
      if (index == 6) { imagen = "ia1-met-retornos-off";    }
      if (index == 7) { imagen = "ia1-met-retornos-on";     }
      if (index == 8) { imagen = "ia1-met-ventas-off";      }
      if (index == 9) { imagen = "ia1-met-ventas-on";       }

      if (index == 10) { imagen = "ia1-met-recuentos-off";   }
      if (index == 11) { imagen = "ia1-met-recuentos-on";    }

      if (index == 12) { imagen = "ia1-met-reubicacion-off"; }
      if (index == 13) { imagen = "ia1-met-reubicacion-on";  }

      if (index == 14) { imagen = "ia1-met-traspasos-off";   }
      if (index == 15) { imagen = "ia1-met-traspasos-on";    }

      if (index == 16) { imagen = "ia1-met-varios-off";      }
      if (index == 17) { imagen = "ia1-met-varios-on";       }
      */

      //-- Obtener URL Completa
      imagen = this.f99ImageName(imagen);

      this.p99Log(5,1,imagen);

      //-- Retornar la imagen a aplicar
      return imagen;
    },


    //--------------------------------------------------------------------------
    //-- Color (en función del Índice aportado) basado en (lnkDefaultMPAColors)
    //--------------------------------------------------------------------------
    lf91MPAColors(indice) {
      return 'background-color:'+global.lnkDefaultMPAColors[indice];
    },

    //----------------------------------------------------------------------
    //-- Aplicar atributos para Color en un Estilo (style)
    //----------------------------------------------------------------------
    lf91EstiloColor(color) {
      return 'background-color:'+color;
    },

    //---------------------------------------------------------------------------------
    //-- Rótulo a Mostrar en el Tag (y la Lista de Artículos)
    //---------------------------------------------------------------------------------
    lf91DescripcionArticulo ({ a001, a002 }) {
      //-- Casos Específicos
      if (a001 == undefined) { return 'Artículo Desconocido'; }

      //-- Resto de Casos
      return a001 + ' - '+ a002;
    },

    //----------------------------------------------------------------------
    //-- Descripción Posición Flechas que indican el (Camino)
    //----------------------------------------------------------------------
    lf91DescripcionPosicionCYR(posicion) {
      //-- Valor predeterminado
      var descripcionCamino = '-- Desconocido --';

      //-- Valores conocidos
      if (posicion == 'A') { descripcionCamino = 'Arriba';      }
      if (posicion == 'B') { descripcionCamino = 'Abajo';       }
      if (posicion == 'D') { descripcionCamino = 'Derecha';     }
      if (posicion == 'I') { descripcionCamino = 'Izquierda';   }
      if (posicion == 'V') { descripcionCamino = 'ZigZag (V)';  }
      if (posicion == 'Z') { descripcionCamino = 'ZigZag (H)';  }

      //-- Retornar el resultado
      return descripcionCamino;
    },

    //----------------------------------------------------------------------
    //-- Dar Formato al ID facilitando su Legibilidad
    //----------------------------------------------------------------------
    lf91FormatoHueco(id) {
      let resultado = id;
      //console.dir(id);

      if (id.length >= 12) {
        resultado = 'Pasillo <b>'+id.substr(2,2)+'</b> Calle <b>'+id.substr(4,2)+'</b> Hueco <b>'+id.substr(7,1)+'</b> Altura <b>'+id.substr(8,1)+'</b>';
      }

      return resultado;
    },

    //...........................................................
    //-- Rótulo del Hueco
    //...........................................................
    lf91RotuloHueco(hueco) {
      if ((hueco.length == 2) && (hueco.substr(0,1) == '0')) {
        return hueco.substr(1,1);
      } else {
        return hueco;
      }
    },

    //--------------------------------------------------------------------------------
    //-- URL de la Imagen 500 x 500
    //--------------------------------------------------------------------------------
    lf91ImagenArticulo500(codigo) {
        //-- Retornar la URL a utilizar
        var URL = global.lnkServerImgArticulosURL+"u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&i="+codigo+"&t=500&x=C";

        //-- Informar Tabla y Método solicitado (y la URL completa)
        this.p99Log(1,1,"URL Imagen 500 () ==> "+URL);

        //-- Devolver el resultado obtenido
        return URL;
    },


    //------------------------------------------------------------------------
    //-- Obtener CANTIDAD almacenada en el Hueco BAM de la posición (indice)
    //------------------------------------------------------------------------
    lf91CantidadBAM(indice) {

      //-- Inicializar
      let canMPA = 0;
      let uniMPA = 0;
      let canDMA = 0;
      let uniDMA = 0;
      let canLOT = 0;
      let uniLOT = 0;

      let j    = 0;

      //-- MPA
      j = 0;
      while (j < this.laRegistrosBAM[indice].mmpa.length) {
        //-- Acumular
        canMPA += this.laRegistrosBAM[indice].mmpa[j].m023;
        uniMPA += this.laRegistrosBAM[indice].mmpa[j].m024;

        //-- Siguiente Índice
        j += 1;
      }

      //-- DMA
      j = 0;
      while (j < this.laRegistrosBAM[indice].mdma.length) {
        //-- Acumular
        canDMA += this.laRegistrosBAM[indice].mdma[j].d060;
        uniDMA += this.laRegistrosBAM[indice].mdma[j].d061;

        //-- Siguiente Índice
        j += 1;
      }

      //-- LOT
      j = 0;
      while (j < this.laRegistrosBAM[indice].mlot.length) {
        //-- Acumular
        canLOT += this.laRegistrosBAM[indice].mlot[j].l060;
        uniLOT += this.laRegistrosBAM[indice].mlot[j].l061;

        //-- Siguiente Índice
        j += 1;
      }

      //-- Concatenar Resultado
      let resultado = '<b>'+this.f91Cantidad(canMPA)+'</b>';    //-- Cantidad Confirmada
      if (uniMPA != 0) { resultado += ' ['+uniMPA+']'; }        //-- Unidades Confirmadas

      if ((canDMA != 0) || (uniDMA != 0)) { 
        resultado += '<span class="igs-l2 igs-color2">';
        resultado += ' ('+this.f91Cantidad(canDMA);             //-- Cantidad en Tránsito
        if (uniDMA != 0) { resultado += ' ['+uniMPA+']'; }      //-- Unidades en Tránsito
        resultado += ')';
        resultado += '</span>';
      }

      if ((canLOT != 0) || (uniLOT != 0)) { 
        resultado += '<span class="igs-l1 igs-color7">';
        resultado += ' {'+this.f91Cantidad(canLOT);             //-- Cantidad Prevista  a Cargar
        if (uniLOT != 0) { resultado += ' ['+uniLOT+']'; }      //-- Unidades Previstas a Cargar
        resultado += '}';
        resultado += '</span>';
      }

      //-- Retornar Resultado COMPLETO
      return  resultado;
    },

    //----------------------------------------------------------------------
    //-- Leyenda para el ToolTip de cada Hueco
    //----------------------------------------------------------------------
    lf91MapaTooltip(indice) {
      //-- En caso de haber desactivado el Tooltip ...
      if (!this.lSWMostrarTooltip) { return ''; }

      //-- Valor Mínimo : (Ubicación y Porcentaje)
      let tooltip = '  '+ this.f93MaquetarUbicacion(this.laHuecosMapa[indice].id,99) +' ('+ this.laHuecosMapa[indice].porcentaje + '%)';

      //-- Añadir (Código y Descripción del Artículo) ubicado en este Hueco
      if ((this.laHuecosMapa[indice].porcentaje != 0) && (this.laHuecosMapa[indice].articulo != '')) {
        tooltip += '  ' + this.laHuecosMapa[indice].articulo + ' '+ this.laHuecosMapa[indice].descripcionArticulo+'  ';
      }
       
      //-- Retornar el valor completo
      return tooltip;
    },

    //----------------------------------------------------------------------
    //-- Leyenda descriptiva de un determinado HUECO
    //----------------------------------------------------------------------
    lp99MostrarLeyenda1() {
      this.p99Log(5,1,' >>> Mostrando la Leyenda .1. para el Hueco del Índice');

      //-- Mostrar Leyenda
      this.$modal.show('VMLeyenda1');
    },

    //----------------------------------------------------------------------
    //-- Antes de OCULTAR la Leyenda .1.
    //----------------------------------------------------------------------
    lp99OcultandoLeyenda1() {
      this.p99Log(5,1,' >>> Ocultando la Leyenda .1.');
    },

    //----------------------------------------------------------------------
    //-- Antes de OCULTAR la Leyenda .2.
    //----------------------------------------------------------------------
    lp99OcultandoLeyenda2() {
      this.p99Log(5,1,' >>> Ocultando la Leyenda .2.');
    },

    //----------------------------------------------------------------------
    //-- Antes de OCULTAR la Leyenda .3.
    //----------------------------------------------------------------------
    lp99OcultandoLeyenda3() {
      this.p99Log(5,1,' >>> Ocultando la Leyenda .3.');
    },


    //----------------------------------------------------------------------
    //-- Filtros a aplicar durante la Consulta del Mapa
    //----------------------------------------------------------------------
    lp99MostrarLeyenda11() {
      this.p99Log(5,1,' >>> Mostrando la Leyenda .1. para el Hueco del Índice');

      //-- Mostrar Leyenda
      this.$modal.show('VMLeyenda11');
    },

    //----------------------------------------------------------------------
    //-- Antes de OCULTAR los Filtros para la Consulta del Mapa
    //----------------------------------------------------------------------
    lp99OcultandoLeyenda11() {
      this.p99Log(5,1,' >>> Ocultando la Leyenda .11.');
    },


    //----------------------------------------------------------------------
    //-- Lectura de los Huecos registrados en el Mapa de Almacén
    //----------------------------------------------------------------------
    lp98LeerMapa(ubicacion) {
      this.p99Log(5,1,' (p98LeerMapa) ubicacion = '+ubicacion);

        //-- Ubicación ....: Demarcación + Pasillo a Leer
        //-- Tipo de Vista : (1)-Planta (2)-Alzada
        //var tipoVista = '1';
        //if (this.lbModoAlzada) { tipoVista = '2'; }
        //-- Concatenar TODOS los Parámetros
        //var parametros = '0111^'+tipoVista;


        //-- Parámetros a enviar
        let swIncluirDescripcionArticulo = 'N';
        if (this.lSWMostrarTooltip) { swIncluirDescripcionArticulo = 'S'; }

        var parametros = this.$store.state.strAlmacenDemarcacion + '^' + swIncluirDescripcionArticulo;
      

        //-- Llamada al Servidor
        var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=001&x=" + encodeURIComponent(parametros);
        this.p99Log(1,1,' (p98LeerMapa) URL = '+URL);

        //-- Contactar con el Servidor del Distribuidor
        axios.get(URL, {responseEncoding: 'utf-8'})                         
        .then(respuesta => {

            //-- Extraer la respuesta recibida
            var datos = respuesta.data;

            //-- Determinar si ha habido EXITO ó ERROR
            var flg = global.lnkGetDataError;
            if (datos.flg) {  flg = datos.flg; }

            //-- Actualizar Resultados
            this.p99Log(5,1,'-- Respuesta (200001) --');
            this.p99Log(1,2,datos);

            if (flg == global.lnkGetDataOk)  {

              //-- Determinar Pausa (en función de los Huecos que van a ser destruidos)
              var lnPausa = 0;
              if (this.laHuecosMapa.length > 0) { lnPausa = 1.1 * this.laHuecosMapa.length; }

              //-- Conservar Lista de Huecos
              this.laRegistrosMPA = datos.t200m001;

              //-- Refrescar Visualización del Mapa
              this.lp90RefrescarMapa(lnPausa);

            } else {
              //-- Informar el Error
              this.p99Log(1,1,' #### ERROR (200001) ####');
              if (datos.msg) { this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1); } else { this.p99AnuncioIA1('2','7','ERROR', respuesta.data,-1); }
            }
        })
        .catch(error => {
            //-- Mostrar el Mensaje de Error oportuno
            this.p99Log(1,1,'** ERROR **');
            this.p99Log(1,1,error);
        });

    },

    //----------------------------------------------------------------------
    //-- Lectura de los REGISTROS CYR
    //----------------------------------------------------------------------
    lp98LeerListaCYR() {
      this.p99Log(5,1,' (lp98LeerListaCYR)');

        //-- Llamada al Servidor
        var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=301&x="+encodeURIComponent(this.ltTipoCYR);
        this.p99Log(1,1,' (lp98LeerListaCYR) URL = '+URL);

        //-- Contactar con el Servidor del Distribuidor
        axios.get(URL, {responseEncoding: 'utf-8'})                         
        .then(respuesta => {

            //-- Extraer la respuesta recibida
            var datos = respuesta.data;

            //-- Determinar si ha habido EXITO ó ERROR
            var flg = global.lnkGetDataError;
            if (datos.flg) {  flg = datos.flg; }

            //-- Actualizar Resultados
            this.p99Log(5,1,'-- Respuesta (200301) --');
            this.p99Log(5,2,datos);

            if (flg == global.lnkGetDataOk)  {

              //-- Reemplazar Lista de HUECOS del Mapa Actual
              this.laRegistrosCYR = datos.t200m301;

              //..........................................
              //-- Reconstruir los Indicadores de Senda
              //..........................................
              this.laSentidoCamino = [];

              let p = {x:10, y:10};
              for (const r of this.laRegistrosCYR) {

                //-- Sentido del Recorrido
                let valorS = 'D';
                if (r.y012 < r.y011) { valorS = 'I'; }

                //-- Ubicación Inicial
                p = this.lf30UbicacionXY(r.y011, r.y013);
                this.laSentidoCamino.push({s:valorS, t:r.y002, x:p.x, y:p.y, color:r.y015});

                //-- Ubicación Final
                p = this.lf30UbicacionXY(r.y012, r.y013);
                this.laSentidoCamino.push({s:valorS, t:r.y002, x:p.x, y:p.y, color:r.y015});
              }

              //-- Mensaje de Éxito
              this.p99Log(5,1,' **** EXITO ****');
              //this.p99AnuncioIA1('1','7','¡ Éxito !', 'Lista de Registros CYR cargada correctamente.',3);

            } else {
              //-- Informar el Error
              this.p99Log(1,1,' #### ERROR (200301) ####');
              if (datos.msg) { this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1); } else { this.p99AnuncioIA1('2','7','ERROR', respuesta.data,-1); }
            }
        })
        .catch(error => {
            //-- Mostrar el Mensaje de Error oportuno
            this.p99Log(1,1,'** ERROR **');
            this.p99Log(1,1,error);
        });

    },


    //----------------------------------------------------------------------
    //-- Lectura de los REGISTROS UXA
    //----------------------------------------------------------------------
    lp98LeerListaUXA() {
      this.p99Log(5,1,' (lp98LeerListaUXA)');

        //-- Llamada al Servidor
        var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=201&x=";
        this.p99Log(1,1,' (lp98LeerListaUXA) URL = '+URL);

        //-- Contactar con el Servidor del Distribuidor
        axios.get(URL, {responseEncoding: 'utf-8'})                         
        .then(respuesta => {

            //-- Extraer la respuesta recibida
            var datos = respuesta.data;

            //-- Determinar si ha habido EXITO ó ERROR
            var flg = global.lnkGetDataError;
            if (datos.flg) {  flg = datos.flg; }

            //-- Actualizar Resultados
            this.p99Log(5,1,'-- Respuesta (200201) --');
            this.p99Log(5,2,datos);

            if (flg == global.lnkGetDataOk)  {

              //-- Reemplazar Lista de HUECOS del Mapa Actual
              this.laRegistrosUXA = datos.t200m201;

              //-- Marcar (a priori) TODAS las Ubicaciones Consultadas
              this.laUXASeleccionados = this.laRegistrosUXA.map(a => a.ucl0);

              //-- Mensaje de Éxito
              this.p99Log(5,1,' **** EXITO ****');
              //this.p99AnuncioIA1('1','7','¡ Éxito !', 'Lista de Registros UXA cargada correctamente.',3);

            } else {
              //-- Informar el Error
              this.p99Log(1,1,' #### ERROR (200201) ####');
              if (datos.msg) { this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1); } else { this.p99AnuncioIA1('2','7','ERROR', respuesta.data,-1); }
            }
        })
        .catch(error => {
            //-- Mostrar el Mensaje de Error oportuno
            this.p99Log(1,1,'** ERROR **');
            this.p99Log(1,1,error);
        });

    },


    //----------------------------------------------------------------------
    //-- Lectura de la Lista de Demarcaciones disponibles
    //----------------------------------------------------------------------
    lp98ListaDemarcaciones() {

      //-- Llamada al Servidor
      var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=151";
      this.p99Log(1,1,' (p98ListaDemarcaciones) URL = '+URL);

      //-- Contactar con el Servidor del Distribuidor
      axios.get(URL, {responseEncoding: 'utf-8'})                         
      .then(respuesta => {

          //-- Extraer la respuesta recibida
          var datos = respuesta.data;

          //-- Determinar si ha habido EXITO ó ERROR
          var flg = global.lnkGetDataError;
          if (datos.flg) {  flg = datos.flg; }

          //-- Actualizar Resultados
          this.p99Log(5,1,'-- Respuesta (200151) --');
          this.p99Log(5,2,datos);

          if (flg == global.lnkGetDataOk)  {

            //-- Actualizar la Lista de Pasillos
            this.laListaDemarcaciones = datos.t200m151;

          } else {
            //-- Informar el Error
            this.p99Log(1,1,' #### ERROR (200151) ####');
            if (datos.msg) { this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1); } else { this.p99AnuncioIA1('2','7','ERROR', respuesta.data,-1); }
          }
      })
      .catch(error => {
          //-- Mostrar el Mensaje de Error oportuno
          this.p99Log(1,1,'** ERROR **');
          this.p99Log(1,1,error);
      });

    },


    //----------------------------------------------------------------------
    //-- Lectura de la Lista de Pasillos disponibles
    //----------------------------------------------------------------------
    lp98ListaPasillos() {

        //-- Lista de Parámetros Adicionales
        var parametros = this.$store.state.strAlmacenDemarcacion;

        //-- Llamada al Servidor
        var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=152&x="+encodeURIComponent(parametros);
        this.p99Log(1,1,' (p98ListaPasillos) URL = '+URL);

        //-- Contactar con el Servidor del Distribuidor
        axios.get(URL, {responseEncoding: 'utf-8'})                         
        .then(respuesta => {

            //-- Extraer la respuesta recibida
            var datos = respuesta.data;

            //-- Determinar si ha habido EXITO ó ERROR
            var flg = global.lnkGetDataError;
            if (datos.flg) {  flg = datos.flg; }

            //-- Actualizar Resultados
            this.p99Log(5,1,'-- Respuesta (200152) --');
            this.p99Log(5,2,datos);

            if (flg == global.lnkGetDataOk)  {

              //-- Actualizar la Lista de Pasillos
              this.laListaPasillos = datos.t200m152;

            } else {
              //-- Informar el Error
              this.p99Log(1,1,' #### ERROR (200152) ####');
              if (datos.msg) { this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1); } else { this.p99AnuncioIA1('2','7','ERROR', respuesta.data,-1); }
            }
        })
        .catch(error => {
            //-- Mostrar el Mensaje de Error oportuno
            this.p99Log(1,1,'** ERROR **');
            this.p99Log(1,1,error);
        });

    },


    //----------------------------------------------------------------------
    //-- Actualizar Mapa (Creando / Reescribiendo los Huecos necesarios)
    //----------------------------------------------------------------------
    lp98EnviarMapa() {
        this.p99Log(5,1,'lp98EnviarMapa');

        this.p99Log(5,1,'-- Mapa QS --');
        var mapaQS = '';

        //-- AGREGAR un registro ({}) por cada HUECO, separando cada ATRIBUTO con la virgulilla (~)
        let top = 0;
        for (const r of this.laHuecosMapa) {

          top = this.f94Round2(r.top)+this.lnMapaOffsetY;
          if (this.lbModoAlzada) { top = 0; }

          if (r.id.substr(0,10) == '0111010110') {
                this.p99Log(5,1,' top=(('+Math.round(r.height/this.lnMapaEscala/this.lnZoomY)+'+10) / '+this.lnZoomY+' / '+this.lnMapaEscala+')/'+r.id.substr(8,1)+') + 250 - '+window.innerHeight);
                this.p99Log(5,1,' innerHeight:'+window.innerHeight+' item.m001.substr(8,1)='+r.id.substr(8,1)+' item.m014='+Math.round(r.height/this.lnMapaEscala/this.lnZoomY));
                this.p99Log(5,1,' {0111010110}  x='+r.left+' y='+r.top+' w='+r.width+' h='+r.height+' :: lnMapaEscala='+this.lnMapaEscala+' :: ZoomY='+this.lnZoomY+' :: OffssetY='+this.lnMapaOffsetY);
                this.p99Log(5,1,' (0111010110)  x='+Math.floor(r.left/this.lnMapaEscala/this.lnZoomX)+' y='+top+' w='+Math.floor(r.width/this.lnMapaEscala/this.lnZoomX)+' h='+Math.floor(r.height/this.lnMapaEscala/this.lnZoomY));
          }

          mapaQS = mapaQS + r.id + '~' + this.f94Round2(r.left/this.lnMapaEscala/this.lnZoomX) + '~'  + top + '~' + this.f94Round2(r.width/this.lnMapaEscala/this.lnZoomX) + '~' + this.f94Round2(r.height/this.lnMapaEscala/this.lnZoomY) + '~' + r.tipoHueco + '~' + r.nroPales +'~' + r.articulo + '^';
        }

        //-- Debug
        this.p99Log(1,1,'-- MapaQS --');
        this.p99Log(1,1,mapaQS,mapaQS.length);

        //-- Llamada al Servidor
        //var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=901&x=" + encodeURIComponent(this.f75GzipB64Texto(mapaQS));
        var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=901&x=" + encodeURIComponent(mapaQS);

        //-- Contactar con el Servidor del Distribuidor
        axios.get(URL, {responseEncoding: 'utf-8'})                         
        .then(respuesta => {

            //-- Extraer la respuesta recibida
            var datos = respuesta.data;

            //-- Determinar si ha habido EXITO ó ERROR
            var flg = global.lnkGetDataError;
            if (datos.flg) {  flg = datos.flg; }

            //-- Actualizar Resultados
            this.p99Log(5,1,'-- Respuesta (200901) --');
            this.p99Log(5,2,datos);

            //-- Evaluar respuesta recibida
            if (flg == global.lnkGetDataOk)  {
              this.p99Log(1,1,' **** EXITO ****');
              this.p99AnuncioIA1('1','7','¡ Éxito !', 'El MAPA ha sido actualizado correctamente.',3);

              //-- Re-Lectura del Mapa a mostrar
              this.lp98LeerMapa('');
            } else {
              this.p99Log(1,1,' #### ERROR (200901) ####');
              if (datos.msg) { this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1); } else { this.p99AnuncioIA1('2','7','ERROR', respuesta.data,-1); }
            }
        })
        .catch(error => {
            //-- Mostrar el Mensaje de Error oportuno
            this.p99Log(1,1,'** ERROR **');
            this.p99Log(1,1,error);
        });

    },

    //---------------------------------------
    //-- Actualizar Panel Derecho
    //---------------------------------------
    lp99ActualizarPanelDerecho() {
      this.p99Log(5,1,'lp99ActualizarPanelDerecho ('+this.lnTipoEdicion+')');

      //-- Revisar Parámetros
      if (this.lnTipoEdicion == 0) {
        this.lbPanelDerechoAbierto = false;
        this.lnMapaEscala = 0.9;
      } else {
        this.lbPanelDerechoAbierto = true;
        this.lnMapaEscala = 0.5;
      }

      this.p99Log(5,1,' -- lp99ActualizarPanelDerecho -- ('+this.lbPanelDerechoAbierto+')  ['+this.lnTipoEdicion+']');

      //-- Repintar el Mapa (Siempre)
      this.lp90RefrescarMapa();

      //-- Refrescar Lista de Registros (si procede)
      if (this.lnTipoEdicion == 1)  {  this.lp98LeerListaUXA(); }
      if (this.lnTipoEdicion == 2)  {  this.lp98LeerListaCYR(); }
      if (this.lnTipoEdicion == 91) {  this.lp98LeerListaMET(); }
    },


  },

  /**************************************************************/
  /****************       Filtros / Formatos     ****************/
  /**************************************************************/
  filters: {
      //..............................................
      //--- Filtro para dar FORMATO a las FECHAS
      //..............................................
      lf60FiltroFecha: function (date) {

          let dw = date.toLocaleDateString('es-ES', { weekday: 'long' });
          dw = dw.charAt(0).toUpperCase() + dw.slice(1);

          //-- Fecha
          const d = date.getDate();
          const m = date.getMonth() + 1;
          //const y = date.getFullYear();
          const y = date.getFullYear();

          //-- Hora
          //const hora = date.getHours();
          //const minutos = date.getMinutes();

          //return dw + ' ' + (d < 10 ? '0' : '') + d + '-' + (m < 10 ? '0' : '') + m + '-' + y + ' ' + (hora < 10 ? '0' : '') + hora + ':' + (minutos < 10 ? '0' : '') + minutos;
          return dw + ' ' + (d < 10 ? '0' : '') + d + '-' + (m < 10 ? '0' : '') + m + '-' + y;
      }
  },


  //----------------------------
  //-- Datos Calculados
  //----------------------------
  computed: {

    //...........................................................
    //-- CSS a aplicar sobre el Area de Trabajo (Planta/Alzada)
    //...........................................................
    lf91ClaseMapa() {
      //-- Clases Combinadas
      if (this.lSWMostrarReticula) {
        if (this.lnTipoEdicion == 0) {
          return "artboard-grid-big ";
        } else {
          return "artboard-grid-small ";
        }
      } else {
        return "";
      }
    },

    //..............................................................
    //-- Estilo a aplicar sobre el Area de Trabajo (Planta/Alzada)
    //..............................................................
    lf91EstiloMapa() {
      return "height: "+((window.innerHeight)*0.85)+"px; width:98%; left: 1%; border: 1px solid red; position: relative";
    },



    //...........................................................
    //-- CSS a aplicar sobre el Area de Trabajo (Planta/Alzada)
    //...........................................................
    lf91ClasePanelDerecho() {
      //-- Ancho del Panel Derecho
      let cssAncho = "col-md-0";
      if (this.lbPanelDerechoAbierto) { cssAncho = "col-md-3"; }

      this.p99Log(5,1,' ** cssAncho (Derecho) = '+cssAncho);

      //-- Retornar el valor calculado
      return cssAncho;
    },

    //...........................................................
    //-- CSS a aplicar sobre el Area de Trabajo (Planta/Alzada)
    //...........................................................
    lf91ClasePanelIzquierdo() {
      //-- Ancho del Panel Derecho
      let cssAncho = "col-md-12";
      if (this.lbPanelDerechoAbierto) { cssAncho = "col-md-9"; }

      this.p99Log(5,1,' ** cssAncho (Izquierdo)  = '+cssAncho);

      //-- Retornar el valor calculado
      return cssAncho;
    },

    //...........................................................
    //-- Clave para el Repintado del Mapa
    //...........................................................
    lf91KeyMapa() {
      this.lp90RefrescarMapa(0);
      return this.laUXASeleccionados.length * 1000;
    },

    //...........................................................
    //-- Título de la Pantalla
    //...........................................................
    lf91TituloPantalla() {
      if (this.lbModoAlzada) {
        return 'Mapa de Almacén (Vista ALZADA)';
      } else {
        return 'Mapa de Almacén (Vista PLANTA)';
      }
    },

    //..................................................................
    //-- Suma de Bultos (Artículos buscado en [Búsqueda de Artículos])
    //..................................................................
    lf91SumaCantidadesBAMLOT() {

      //-- Inicializar
      let canMPA = 0;
      let uniMPA = 0;
      let canDMA = 0;
      let uniDMA = 0;
      let canLOT = 0;
      let uniLOT = 0;

      let i    = 0;
      let j    = 0;

      //-- Acumular
      while (i < this.laRegistrosBAM.length) {

        //-- MPA
        j = 0;
        while (j < this.laRegistrosBAM[i].mmpa.length) {
          //-- Acumular
          canMPA += this.laRegistrosBAM[i].mmpa[j].m023;
          uniMPA += this.laRegistrosBAM[i].mmpa[j].m024;

          //-- Siguiente Índice
          j += 1;
        }

        //-- DMA
        j = 0;
        while (j < this.laRegistrosBAM[i].mdma.length) {
          //-- Acumular
          canDMA += this.laRegistrosBAM[i].mdma[j].d060;
          uniDMA += this.laRegistrosBAM[i].mdma[j].d061;

          //-- Siguiente Índice
          j += 1;
        }

        //-- LOT
        j = 0;
        while (j < this.laRegistrosBAM[i].mlot.length) {
          //-- Acumular
          canLOT += this.laRegistrosBAM[i].mlot[j].l060;
          uniLOT += this.laRegistrosBAM[i].mlot[j].l061;

          //-- Siguiente Índice
          j += 1;
        }

        //-- Siguiente HUECO
        i     += 1;
      }

      //-- Devolver resultado acumulado (en formato de ARRAY)
      return  [canMPA, uniMPA, canDMA, uniDMA, canLOT, uniLOT];

    }
 

  },


  //--------------------------------------
  //-- Observadores CAMBIOS de Valor, ...
  //--------------------------------------
  watch: {
    //.............................................................
    //-- Al MODIFICAR el valor del CÓDIGO DE ARTÍCULO a BUSCAR
    //.............................................................
    ltArticuloBusquedaCodigo: function(newVal, oldVal) {
      this.p99Log(5,1,'--- lp60DetenerCambiosArticuloBusqueda ----'+oldVal+' -> '+newVal);
      this.lbCambioArticuloBusquedaEnCurso = true;
      this.ltArticuloBusquedaDescripcion = " Esperando a completar el CÓDIGO ...";
    }
  },


  //----------------------------
  //-- Al cargar esta página
  //----------------------------
  mounted() {
      //-- Actualizar Módulo "Vivo"
      this.$store.commit('p10StrModuleName', 'SVC11100');

      //-- Lectura de la Lista de Demarcaciones
      this.lp98ListaDemarcaciones();

      //-- Lectura de la Lista de Pasillos disponibles
      this.lp98ListaPasillos();

      //-- Lectura del Mapa a mostrar
      this.lp98LeerMapa('');

      //-- Reclamar contenido a mostrar
      if (this.lnTipoEdicion == 91) {  this.lp98LeerListaMET(); }

      //-- Actualizar BreadCrumb 
      this.$store.commit('p10StrMenuBC', [
        {
          text: "1. MAPA",
          href: "#"
        },
        {
          text: "1.1 Diseño Mapa",
          href: "#",
          to: "/00001"
        },
        {
          text: "1.1.1 Editar Mapa Almacén",
          active: true
        }
    ]);
  },


  //---------------------------------------
  //-- Antes de abandonar la Página Actual
  //---------------------------------------
  beforeDestroy () {
    this.p99Log(5,1,'** Eliminando Timer (beforeUnmount) **');
    this.lp90EliminarTimer();
  }

}
</script>


<style lang="scss">
//---------------------------------------------
//-- Estilo ESPECÍFICO para la presente VISTA
//---------------------------------------------

.artboard-grid-small {
  min-height: 200vh;
  height: 400vh;
  width: 100%;
  margin: 0;
  background-image: repeating-linear-gradient(0deg, transparent, transparent 99px, 99px, rgba(0, 0, 0, 0.4) 100px), repeating-linear-gradient(-90deg, transparent, transparent 99px, 99px, rgba(0, 0, 0, 0.4) 100px), repeating-linear-gradient(0deg, transparent, transparent 9px, 9px, rgba(0, 0, 0, 0.25) 10px), repeating-linear-gradient(-90deg, transparent, transparent 9px, 9px, rgba(0, 0, 0, 0.25) 10px);
  background-size: 100px 100px; }

.artboard-grid-big {
  min-height: 200vh;
  min-width: 200vw;
  height: 400vh;
  width: 400vw;
  margin: 0;
  background-image: repeating-linear-gradient(0deg, transparent, transparent 99px, 99px, rgba(0, 0, 0, 0.4) 100px), repeating-linear-gradient(-90deg, transparent, transparent 99px, 99px, rgba(0, 0, 0, 0.4) 100px), repeating-linear-gradient(0deg, transparent, transparent 9px, 9px, rgba(0, 0, 0, 0.25) 10px), repeating-linear-gradient(-90deg, transparent, transparent 9px, 9px, rgba(0, 0, 0, 0.25) 10px);
  background-size: 100px 100px; }

.artboard-overlay {
  background-image: repeating-linear-gradient(0deg, transparent, transparent 99px, 99px, rgba(0, 0, 0, 0.4) 100px), repeating-linear-gradient(-90deg, transparent, transparent 99px, 99px, rgba(0, 0, 0, 0.4) 100px), repeating-linear-gradient(0deg, transparent, transparent 9px, 9px, rgba(0, 0, 0, 0.25) 10px), repeating-linear-gradient(-90deg, transparent, transparent 9px, 9px, rgba(0, 0, 0, 0.25) 10px);
  background-size: 100px 100px;
  min-height: 200vh;
  min-width: 200vw;
  height: 400vh;
  width: 400vw;
  opacity: 0.4;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 1000; 
}

//------------------

.filler {
        width: 100%;
        height: 100%;
        left: 0px;
        display: inline-block;
        position: absolute;
}

.list {
        position: absolute;
        top: 30px;
        bottom: 30px;
        left: 30px;
        right: 300px;
        box-shadow: 0 0 2px #AAA;
        background-color: white;
    }

.box-hueco {
        box-shadow:  1px 1px 2px 0px rgba(125,125,125,1);
}  

.box-encontrado {
        box-shadow:  10px 10px 15px 0px rgba(0,224,0,1);
}  

.box-resaltado {
        box-shadow:  10px 10px 15px 0px rgba(255,153,51,1);
}  

.box-silo-mono {
        box-shadow:  10px 10px 15px 0px rgba(125,125,125,1);
}  

.box-silo-mono:after {
    content: '';
    position: absolute;
    left: 1px;
    right: 1px;
    top: 0;
    bottom: 0;
    border-width: 2px;
    border-style: dotted;
    border-color: #001122;
}

.box-silo-multi {
        box-shadow:  10px 10px 15px 0px rgba(125,125,200,1);
}  

.box-silo-multi:after {
    content: '';
    position: absolute;
    left: 1px;
    right: 1px;
    top: 0;
    bottom: 0;
    border-width: 2px;
    border-style: dashed;
    border-color: #444444;
}

//-- CheckBox

.checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
}

.checkbox > span {
    color: #ffffff;
    padding: 0.25rem 0.25rem;
}

.checkbox > input {
    height: 16px;
    width: 16px;
    margin-top: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid #34495E;
    border-radius: 4px;
    outline: none;
    transition-duration: 0.3s;
    background-color: #ff0000;
    cursor: pointer;
  }

.checkbox > input:checked {
    border: 1px solid #0000ff;
    background-color: #41B883;
}

.checkbox > input:checked + span::before {
    content: '\2713';
    display: block;
    text-align: center;
    color: #41B883;
    position: absolute;
    left: 0.7rem;
    top: 0.2rem;
}

.checkbox > input:active {
    border: 2px solid #ff0000;
}

//----------------------------------------------------

.arrowA {
  animation: slideA 1s ease-in-out infinite;
  margin-bottom: 10px;
}

.arrowB {
  animation: slideB 1s ease-in-out infinite;
  margin-top: 10px;
}

.arrowD {
  animation: slideD 1s ease-in-out infinite;
  margin-left: 10px;
}

.arrowI {
  animation: slideI 1s ease-in-out infinite;
  margin-right: 10px;
}

//-----------------------------------------------------

[data-tooltip]::before {
    content : attr(data-tooltip);
    opacity : 0;
    position : absolute;
    top:0;
    right:0;
}

[data-tooltip]:hover::before {  
    background-color: #333333;
    color: #ffffff;
    padding: 8 8 8 8;
    transition: all 0.1s ease 0.5s;
    visibility: visible;    
    width: 180px;
    height: auto;
    opacity : 1;
    font-size:1.2em;
    z-index:100;
}

[data-tooltip]:not([data-tooltip-persistent])::before {
    pointer-events: none;
}

  //-----------------------------
  //-- MultiSelect
  //-----------------------------

  .multiselect {
    min-height: unset;
  }

  .multiselect__select {
    min-height: unset;
    margin-top: -15px;
  }

  // fix multiselect weird height when using a placeholder
  .multiselect__single {
    margin-top: 5px;
    height: 10px !important;
    min-height: 0px !important;
    line-height: 0px !important;
    font-size: unset;
  }

  .multiselect__placeholder {
    display: inline-block !important;
    margin-bottom: 0px !important;
    padding-top: 0px !important;
    height: 12px !important;
  }
  
  // error class on multiselect
  .multiselect.invalid .multiselect__tags {
    border: 1px solid #f86c6b !important;
  }
  
  //-- Las opciones seleccionadas
  .multiselect__option--selected {
      background: #f3f3f3;
      color: #fd8b0d;
      font-weight: bold;
  }
  
  // override default multiselect styles
  .multiselect__option--highlight {
    background: #595cde !important;
  }
  
  .multiselect__option--highlight:after {
    background: #fd8b0d !important;
  }
  
  .multiselect__tags {
    padding: 5px !important;
    min-height:12px !important;
    height:22px;
    //min-height: 10px;
  }
  
  .multiselect__placeholder{
    margin-left: 10px;
    margin-top: 2px;
    //font-size:12px !important;
  }
  
  .multiselect__tag {
    background: #fd8b0d !important;
    border: 1px solid rgba(60, 60, 60, 0.26) !important;
    color: black !important;
    margin-bottom: 0px !important;
    margin-right: 5px !important;
  }
  
  .multiselect__tag-icon:after {
    color: rgba(60, 60, 60, 0.5) !important;
  }
  
  .multiselect__tag-icon:focus,
  .multiselect__tag-icon:hover {
    background: #fd8b0d !important;
  }
  
  .multiselect__tag-icon:focus:after,
  .multiselect__tag-icon:hover:after {
    color: red !important;
  }

//-----------------------------------------------------
// KeyFrames
//-----------------------------------------------------

@keyframes slideA {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, 10px);
  }
}

@keyframes slideB {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, 10px);
  }
}


@keyframes slideD {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(10px, 0);
  }
}

@keyframes slideI {
  0%,
  100% {
    transform: translate(10px, 0);
  }

  50% {
    transform: translate(0, 0);
  }
  
}

</style>
